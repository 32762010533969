var obj = {};
obj.provinces = {
    CN: {
        '20': '北京',
        '38': '天津',
        '56': '河北',
        '240': '山西',
        '372': '内蒙古',
        '486': '辽宁',
        '601': '吉林',
        '671': '黑龙江',
        '813': '上海',
        '832': '江苏',
        '948': '浙江',
        '1050': '安徽',
        '1172': '福建',
        '1267': '江西',
        '1379': '山东',
        '1537': '河南',
        '1715': '湖北',
        '1833': '湖南',
        '1970': '广东',
        '2113': '广西',
        '2237': '海南',
        '2265': '重庆',
        '2305': '四川',
        '2508': '贵州',
        '2606': '云南',
        '2752': '西藏',
        '2833': '陕西',
        '2951': '甘肃',
        '3052': '青海',
        '3104': '宁夏',
        '3132': '新疆',
        '3307': '香港',
        '3308': '澳门',
        '3309': '台湾',
    },
};

obj.source = {
    '20': {
        '21': {
            name: '北京',
            area: {
                '22': {
                    name: '东城区',
                },
                '23': {
                    name: '西城区',
                },
                '24': {
                    name: '朝阳区',
                },
                '25': {
                    name: '丰台区',
                },
                '26': {
                    name: '石景山区',
                },
                '27': {
                    name: '海淀区',
                },
                '28': {
                    name: '门头沟区',
                },
                '29': {
                    name: '房山区',
                },
                '30': {
                    name: '通州区',
                },
                '31': {
                    name: '顺义区',
                },
                '32': {
                    name: '昌平区',
                },
                '33': {
                    name: '大兴区',
                },
                '34': {
                    name: '怀柔区',
                },
                '35': {
                    name: '平谷区',
                },
                '36': {
                    name: '密云县',
                },
                '37': {
                    name: '延庆县',
                },
            },
        },
    },
    '38': {
        '39': {
            name: '天津',
            area: {
                '40': {
                    name: '和平区',
                },
                '41': {
                    name: '河东区',
                },
                '42': {
                    name: '河西区',
                },
                '43': {
                    name: '南开区',
                },
                '44': {
                    name: '河北区',
                },
                '45': {
                    name: '红桥区',
                },
                '46': {
                    name: '东丽区',
                },
                '47': {
                    name: '西青区',
                },
                '48': {
                    name: '津南区',
                },
                '49': {
                    name: '北辰区',
                },
                '50': {
                    name: '武清区',
                },
                '51': {
                    name: '宝坻区',
                },
                '52': {
                    name: '滨海新区',
                },
                '53': {
                    name: '宁河县',
                },
                '54': {
                    name: '静海县',
                },
                '55': {
                    name: '蓟县',
                },
            },
        },
    },
    '56': {
        '57': {
            name: '石家庄',
            area: {
                '58': {
                    name: '长安区',
                },
                '59': {
                    name: '桥东区',
                },
                '60': {
                    name: '桥西区',
                },
                '61': {
                    name: '新华区',
                },
                '62': {
                    name: '井陉矿区',
                },
                '63': {
                    name: '裕华区',
                },
                '64': {
                    name: '井陉县',
                },
                '65': {
                    name: '正定县',
                },
                '66': {
                    name: '栾城县',
                },
                '67': {
                    name: '行唐县',
                },
                '68': {
                    name: '灵寿县',
                },
                '69': {
                    name: '高邑县',
                },
                '70': {
                    name: '深泽县',
                },
                '71': {
                    name: '赞皇县',
                },
                '72': {
                    name: '无极县',
                },
                '73': {
                    name: '平山县',
                },
                '74': {
                    name: '元氏县',
                },
                '75': {
                    name: '赵县',
                },
                '76': {
                    name: '辛集',
                },
                '77': {
                    name: '藁城',
                },
                '78': {
                    name: '晋州',
                },
                '79': {
                    name: '新乐',
                },
                '80': {
                    name: '鹿泉',
                },
            },
        },
        '81': {
            name: '唐山',
            area: {
                '82': {
                    name: '路南区',
                },
                '83': {
                    name: '路北区',
                },
                '84': {
                    name: '古冶区',
                },
                '85': {
                    name: '开平区',
                },
                '86': {
                    name: '丰南区',
                },
                '87': {
                    name: '丰润区',
                },
                '88': {
                    name: '曹妃甸区',
                },
                '89': {
                    name: '滦县',
                },
                '90': {
                    name: '滦南县',
                },
                '91': {
                    name: '乐亭县',
                },
                '92': {
                    name: '迁西县',
                },
                '93': {
                    name: '玉田县',
                },
                '94': {
                    name: '遵化',
                },
                '95': {
                    name: '迁安',
                },
            },
        },
        '96': {
            name: '秦皇岛',
            area: {
                '97': {
                    name: '海港区',
                },
                '98': {
                    name: '山海关区',
                },
                '99': {
                    name: '北戴河区',
                },
                '100': {
                    name: '青龙',
                },
                '101': {
                    name: '昌黎县',
                },
                '102': {
                    name: '抚宁县',
                },
                '103': {
                    name: '卢龙县',
                },
            },
        },
        '104': {
            name: '邯郸',
            area: {
                '105': {
                    name: '邯山区',
                },
                '106': {
                    name: '丛台区',
                },
                '107': {
                    name: '复兴区',
                },
                '108': {
                    name: '峰峰矿区',
                },
                '109': {
                    name: '邯郸县',
                },
                '110': {
                    name: '临漳县',
                },
                '111': {
                    name: '成安县',
                },
                '112': {
                    name: '大名县',
                },
                '113': {
                    name: '涉县',
                },
                '114': {
                    name: '磁县',
                },
                '115': {
                    name: '肥乡县',
                },
                '116': {
                    name: '永年县',
                },
                '117': {
                    name: '邱县',
                },
                '118': {
                    name: '鸡泽县',
                },
                '119': {
                    name: '广平县',
                },
                '120': {
                    name: '馆陶县',
                },
                '121': {
                    name: '魏县',
                },
                '122': {
                    name: '曲周县',
                },
                '123': {
                    name: '武安',
                },
            },
        },
        '124': {
            name: '邢台',
            area: {
                '125': {
                    name: '桥东区',
                },
                '126': {
                    name: '桥西区',
                },
                '127': {
                    name: '邢台县',
                },
                '128': {
                    name: '临城县',
                },
                '129': {
                    name: '内丘县',
                },
                '130': {
                    name: '柏乡县',
                },
                '131': {
                    name: '隆尧县',
                },
                '132': {
                    name: '任县',
                },
                '133': {
                    name: '南和县',
                },
                '134': {
                    name: '宁晋县',
                },
                '135': {
                    name: '巨鹿县',
                },
                '136': {
                    name: '新河县',
                },
                '137': {
                    name: '广宗县',
                },
                '138': {
                    name: '平乡县',
                },
                '139': {
                    name: '威县',
                },
                '140': {
                    name: '清河县',
                },
                '141': {
                    name: '临西县',
                },
                '142': {
                    name: '南宫',
                },
                '143': {
                    name: '沙河',
                },
            },
        },
        '144': {
            name: '保定',
            area: {
                '145': {
                    name: '新市区',
                },
                '146': {
                    name: '北市区',
                },
                '147': {
                    name: '南市区',
                },
                '148': {
                    name: '满城县',
                },
                '149': {
                    name: '清苑县',
                },
                '150': {
                    name: '涞水县',
                },
                '151': {
                    name: '阜平县',
                },
                '152': {
                    name: '徐水县',
                },
                '153': {
                    name: '定兴县',
                },
                '154': {
                    name: '唐县',
                },
                '155': {
                    name: '高阳县',
                },
                '156': {
                    name: '容城县',
                },
                '157': {
                    name: '涞源县',
                },
                '158': {
                    name: '望都县',
                },
                '159': {
                    name: '安新县',
                },
                '160': {
                    name: '易县',
                },
                '161': {
                    name: '曲阳县',
                },
                '162': {
                    name: '蠡县',
                },
                '163': {
                    name: '顺平县',
                },
                '164': {
                    name: '博野县',
                },
                '165': {
                    name: '雄县',
                },
                '166': {
                    name: '涿州',
                },
                '167': {
                    name: '定州',
                },
                '168': {
                    name: '安国',
                },
                '169': {
                    name: '高碑店',
                },
            },
        },
        '170': {
            name: '张家口',
            area: {
                '171': {
                    name: '桥东区',
                },
                '172': {
                    name: '桥西区',
                },
                '173': {
                    name: '宣化区',
                },
                '174': {
                    name: '下花园区',
                },
                '175': {
                    name: '宣化县',
                },
                '176': {
                    name: '张北县',
                },
                '177': {
                    name: '康保县',
                },
                '178': {
                    name: '沽源县',
                },
                '179': {
                    name: '尚义县',
                },
                '180': {
                    name: '蔚县',
                },
                '181': {
                    name: '阳原县',
                },
                '182': {
                    name: '怀安县',
                },
                '183': {
                    name: '万全县',
                },
                '184': {
                    name: '怀来县',
                },
                '185': {
                    name: '涿鹿县',
                },
                '186': {
                    name: '赤城县',
                },
                '187': {
                    name: '崇礼县',
                },
            },
        },
        '188': {
            name: '承德',
            area: {
                '189': {
                    name: '双桥区',
                },
                '190': {
                    name: '双滦区',
                },
                '191': {
                    name: '鹰手营子矿区',
                },
                '192': {
                    name: '承德县',
                },
                '193': {
                    name: '兴隆县',
                },
                '194': {
                    name: '平泉县',
                },
                '195': {
                    name: '滦平县',
                },
                '196': {
                    name: '隆化县',
                },
                '197': {
                    name: '丰宁',
                },
                '198': {
                    name: '宽城',
                },
                '199': {
                    name: '围场',
                },
            },
        },
        '200': {
            name: '沧州',
            area: {
                '201': {
                    name: '新华区',
                },
                '202': {
                    name: '运河区',
                },
                '203': {
                    name: '沧县',
                },
                '204': {
                    name: '青县',
                },
                '205': {
                    name: '东光县',
                },
                '206': {
                    name: '海兴县',
                },
                '207': {
                    name: '盐山县',
                },
                '208': {
                    name: '肃宁县',
                },
                '209': {
                    name: '南皮县',
                },
                '210': {
                    name: '吴桥县',
                },
                '211': {
                    name: '献县',
                },
                '212': {
                    name: '孟村',
                },
                '213': {
                    name: '泊头',
                },
                '214': {
                    name: '任丘',
                },
                '215': {
                    name: '黄骅',
                },
                '216': {
                    name: '河间',
                },
            },
        },
        '217': {
            name: '廊坊',
            area: {
                '218': {
                    name: '安次区',
                },
                '219': {
                    name: '广阳区',
                },
                '220': {
                    name: '固安县',
                },
                '221': {
                    name: '永清县',
                },
                '222': {
                    name: '香河县',
                },
                '223': {
                    name: '大城县',
                },
                '224': {
                    name: '文安县',
                },
                '225': {
                    name: '大厂',
                },
                '226': {
                    name: '霸州',
                },
                '227': {
                    name: '三河',
                },
            },
        },
        '228': {
            name: '衡水',
            area: {
                '229': {
                    name: '桃城区',
                },
                '230': {
                    name: '枣强县',
                },
                '231': {
                    name: '武邑县',
                },
                '232': {
                    name: '武强县',
                },
                '233': {
                    name: '饶阳县',
                },
                '234': {
                    name: '安平县',
                },
                '235': {
                    name: '故城县',
                },
                '236': {
                    name: '景县',
                },
                '237': {
                    name: '阜城县',
                },
                '238': {
                    name: '冀州',
                },
                '239': {
                    name: '深州',
                },
            },
        },
    },
    '240': {
        '241': {
            name: '太原',
            area: {
                '242': {
                    name: '小店区',
                },
                '243': {
                    name: '迎泽区',
                },
                '244': {
                    name: '杏花岭区',
                },
                '245': {
                    name: '尖草坪区',
                },
                '246': {
                    name: '万柏林区',
                },
                '247': {
                    name: '晋源区',
                },
                '248': {
                    name: '清徐县',
                },
                '249': {
                    name: '阳曲县',
                },
                '250': {
                    name: '娄烦县',
                },
                '251': {
                    name: '古交',
                },
            },
        },
        '252': {
            name: '大同',
            area: {
                '253': {
                    name: '城区',
                },
                '254': {
                    name: '矿区',
                },
                '255': {
                    name: '南郊区',
                },
                '256': {
                    name: '新荣区',
                },
                '257': {
                    name: '阳高县',
                },
                '258': {
                    name: '天镇县',
                },
                '259': {
                    name: '广灵县',
                },
                '260': {
                    name: '灵丘县',
                },
                '261': {
                    name: '浑源县',
                },
                '262': {
                    name: '左云县',
                },
                '263': {
                    name: '大同县',
                },
            },
        },
        '264': {
            name: '阳泉',
            area: {
                '265': {
                    name: '城区',
                },
                '266': {
                    name: '矿区',
                },
                '267': {
                    name: '郊区',
                },
                '268': {
                    name: '平定县',
                },
                '269': {
                    name: '盂县',
                },
            },
        },
        '270': {
            name: '长治',
            area: {
                '271': {
                    name: '城区',
                },
                '272': {
                    name: '郊区',
                },
                '273': {
                    name: '长治县',
                },
                '274': {
                    name: '襄垣县',
                },
                '275': {
                    name: '屯留县',
                },
                '276': {
                    name: '平顺县',
                },
                '277': {
                    name: '黎城县',
                },
                '278': {
                    name: '壶关县',
                },
                '279': {
                    name: '长子县',
                },
                '280': {
                    name: '武乡县',
                },
                '281': {
                    name: '沁县',
                },
                '282': {
                    name: '沁源县',
                },
                '283': {
                    name: '潞城',
                },
            },
        },
        '284': {
            name: '晋城',
            area: {
                '285': {
                    name: '晋城市市辖区',
                },
                '286': {
                    name: '城区',
                },
                '287': {
                    name: '沁水县',
                },
                '288': {
                    name: '阳城县',
                },
                '289': {
                    name: '陵川县',
                },
                '290': {
                    name: '泽州县',
                },
                '291': {
                    name: '高平',
                },
            },
        },
        '292': {
            name: '朔州',
            area: {
                '293': {
                    name: '朔城区',
                },
                '294': {
                    name: '平鲁区',
                },
                '295': {
                    name: '山阴县',
                },
                '296': {
                    name: '应县',
                },
                '297': {
                    name: '右玉县',
                },
                '298': {
                    name: '怀仁县',
                },
            },
        },
        '299': {
            name: '晋中',
            area: {
                '300': {
                    name: '榆次区',
                },
                '301': {
                    name: '榆社县',
                },
                '302': {
                    name: '左权县',
                },
                '303': {
                    name: '和顺县',
                },
                '304': {
                    name: '昔阳县',
                },
                '305': {
                    name: '寿阳县',
                },
                '306': {
                    name: '太谷县',
                },
                '307': {
                    name: '祁县',
                },
                '308': {
                    name: '平遥县',
                },
                '309': {
                    name: '灵石县',
                },
                '310': {
                    name: '介休',
                },
            },
        },
        '311': {
            name: '运城',
            area: {
                '312': {
                    name: '盐湖区',
                },
                '313': {
                    name: '临猗县',
                },
                '314': {
                    name: '万荣县',
                },
                '315': {
                    name: '闻喜县',
                },
                '316': {
                    name: '稷山县',
                },
                '317': {
                    name: '新绛县',
                },
                '318': {
                    name: '绛县',
                },
                '319': {
                    name: '垣曲县',
                },
                '320': {
                    name: '夏县',
                },
                '321': {
                    name: '平陆县',
                },
                '322': {
                    name: '芮城县',
                },
                '323': {
                    name: '永济',
                },
                '324': {
                    name: '河津',
                },
            },
        },
        '325': {
            name: '忻州',
            area: {
                '326': {
                    name: '忻府区',
                },
                '327': {
                    name: '定襄县',
                },
                '328': {
                    name: '五台县',
                },
                '329': {
                    name: '代县',
                },
                '330': {
                    name: '繁峙县',
                },
                '331': {
                    name: '宁武县',
                },
                '332': {
                    name: '静乐县',
                },
                '333': {
                    name: '神池县',
                },
                '334': {
                    name: '五寨县',
                },
                '335': {
                    name: '岢岚县',
                },
                '336': {
                    name: '河曲县',
                },
                '337': {
                    name: '保德县',
                },
                '338': {
                    name: '偏关县',
                },
                '339': {
                    name: '原平',
                },
            },
        },
        '340': {
            name: '临汾',
            area: {
                '341': {
                    name: '尧都区',
                },
                '342': {
                    name: '曲沃县',
                },
                '343': {
                    name: '翼城县',
                },
                '344': {
                    name: '襄汾县',
                },
                '345': {
                    name: '洪洞县',
                },
                '346': {
                    name: '古县',
                },
                '347': {
                    name: '安泽县',
                },
                '348': {
                    name: '浮山县',
                },
                '349': {
                    name: '吉县',
                },
                '350': {
                    name: '乡宁县',
                },
                '351': {
                    name: '大宁县',
                },
                '352': {
                    name: '隰县',
                },
                '353': {
                    name: '永和县',
                },
                '354': {
                    name: '蒲县',
                },
                '355': {
                    name: '汾西县',
                },
                '356': {
                    name: '侯马',
                },
                '357': {
                    name: '霍州',
                },
            },
        },
        '358': {
            name: '吕梁',
            area: {
                '359': {
                    name: '离石区',
                },
                '360': {
                    name: '文水县',
                },
                '361': {
                    name: '交城县',
                },
                '362': {
                    name: '兴县',
                },
                '363': {
                    name: '临县',
                },
                '364': {
                    name: '柳林县',
                },
                '365': {
                    name: '石楼县',
                },
                '366': {
                    name: '岚县',
                },
                '367': {
                    name: '方山县',
                },
                '368': {
                    name: '中阳县',
                },
                '369': {
                    name: '交口县',
                },
                '370': {
                    name: '孝义',
                },
                '371': {
                    name: '汾阳',
                },
            },
        },
    },
    '372': {
        '373': {
            name: '呼和浩特',
            area: {
                '374': {
                    name: '新城区',
                },
                '375': {
                    name: '回民区',
                },
                '376': {
                    name: '玉泉区',
                },
                '377': {
                    name: '赛罕区',
                },
                '378': {
                    name: '土默特左旗',
                },
                '379': {
                    name: '托克托县',
                },
                '380': {
                    name: '和林格尔县',
                },
                '381': {
                    name: '清水河县',
                },
                '382': {
                    name: '武川县',
                },
            },
        },
        '383': {
            name: '包头',
            area: {
                '384': {
                    name: '东河区',
                },
                '385': {
                    name: '昆都仑区',
                },
                '386': {
                    name: '青山区',
                },
                '387': {
                    name: '石拐区',
                },
                '388': {
                    name: '白云鄂博矿区',
                },
                '389': {
                    name: '九原区',
                },
                '390': {
                    name: '土默特右旗',
                },
                '391': {
                    name: '固阳县',
                },
                '392': {
                    name: '达尔罕茂明安联合旗',
                },
            },
        },
        '393': {
            name: '乌海',
            area: {
                '394': {
                    name: '海勃湾区',
                },
                '395': {
                    name: '海南区',
                },
                '396': {
                    name: '乌达区',
                },
            },
        },
        '397': {
            name: '赤峰',
            area: {
                '398': {
                    name: '红山区',
                },
                '399': {
                    name: '元宝山区',
                },
                '400': {
                    name: '松山区',
                },
                '401': {
                    name: '阿鲁科尔沁旗',
                },
                '402': {
                    name: '巴林左旗',
                },
                '403': {
                    name: '巴林右旗',
                },
                '404': {
                    name: '林西县',
                },
                '405': {
                    name: '克什克腾旗',
                },
                '406': {
                    name: '翁牛特旗',
                },
                '407': {
                    name: '喀喇沁旗',
                },
                '408': {
                    name: '宁城县',
                },
                '409': {
                    name: '敖汉旗',
                },
            },
        },
        '410': {
            name: '通辽',
            area: {
                '411': {
                    name: '科尔沁区',
                },
                '412': {
                    name: '科尔沁左翼中旗',
                },
                '413': {
                    name: '科尔沁左翼后旗',
                },
                '414': {
                    name: '开鲁县',
                },
                '415': {
                    name: '库伦旗',
                },
                '416': {
                    name: '奈曼旗',
                },
                '417': {
                    name: '扎鲁特旗',
                },
                '418': {
                    name: '霍林郭勒',
                },
            },
        },
        '419': {
            name: '鄂尔多斯',
            area: {
                '420': {
                    name: '东胜区',
                },
                '421': {
                    name: '达拉特旗',
                },
                '422': {
                    name: '准格尔旗',
                },
                '423': {
                    name: '鄂托克前旗',
                },
                '424': {
                    name: '鄂托克旗',
                },
                '425': {
                    name: '杭锦旗',
                },
                '426': {
                    name: '乌审旗',
                },
                '427': {
                    name: '伊金霍洛旗',
                },
            },
        },
        '428': {
            name: '呼伦贝尔',
            area: {
                '429': {
                    name: '海拉尔区',
                },
                '430': {
                    name: '阿荣旗',
                },
                '431': {
                    name: '莫力达瓦',
                },
                '432': {
                    name: '鄂伦春',
                },
                '433': {
                    name: '鄂温',
                },
                '434': {
                    name: '陈巴尔虎旗',
                },
                '435': {
                    name: '新巴尔虎左旗',
                },
                '436': {
                    name: '新巴尔虎右旗',
                },
                '437': {
                    name: '满洲里',
                },
                '438': {
                    name: '牙克石',
                },
                '439': {
                    name: '扎兰屯',
                },
                '440': {
                    name: '额尔古纳',
                },
                '441': {
                    name: '根河',
                },
            },
        },
        '442': {
            name: '巴彦淖尔',
            area: {
                '443': {
                    name: '临河区',
                },
                '444': {
                    name: '五原县',
                },
                '445': {
                    name: '磴口县',
                },
                '446': {
                    name: '乌拉特前旗',
                },
                '447': {
                    name: '乌拉特中旗',
                },
                '448': {
                    name: '乌拉特后旗',
                },
                '449': {
                    name: '杭锦后旗',
                },
            },
        },
        '450': {
            name: '乌兰察布',
            area: {
                '451': {
                    name: '集宁区',
                },
                '452': {
                    name: '卓资县',
                },
                '453': {
                    name: '化德县',
                },
                '454': {
                    name: '商都县',
                },
                '455': {
                    name: '兴和县',
                },
                '456': {
                    name: '凉城县',
                },
                '457': {
                    name: '察哈尔右翼前旗',
                },
                '458': {
                    name: '察哈尔右翼中旗',
                },
                '459': {
                    name: '察哈尔右翼后旗',
                },
                '460': {
                    name: '四子王旗',
                },
                '461': {
                    name: '丰镇',
                },
            },
        },
        '462': {
            name: '兴安盟',
            area: {
                '463': {
                    name: '乌兰浩特',
                },
                '464': {
                    name: '阿尔山',
                },
                '465': {
                    name: '科尔沁右翼前旗',
                },
                '466': {
                    name: '科尔沁右翼中旗',
                },
                '467': {
                    name: '扎赉特旗',
                },
                '468': {
                    name: '突泉县',
                },
            },
        },
        '469': {
            name: '锡林郭勒',
            area: {
                '470': {
                    name: '二连浩特',
                },
                '471': {
                    name: '锡林浩特',
                },
                '472': {
                    name: '阿巴嘎旗',
                },
                '473': {
                    name: '苏尼特左旗',
                },
                '474': {
                    name: '苏尼特右旗',
                },
                '475': {
                    name: '东乌珠穆沁旗',
                },
                '476': {
                    name: '西乌珠穆沁旗',
                },
                '477': {
                    name: '太仆寺旗',
                },
                '478': {
                    name: '镶黄旗',
                },
                '479': {
                    name: '正镶白旗',
                },
                '480': {
                    name: '正蓝旗',
                },
                '481': {
                    name: '多伦县',
                },
            },
        },
        '482': {
            name: '阿拉善盟',
            area: {
                '483': {
                    name: '阿拉善左旗',
                },
                '484': {
                    name: '阿拉善右旗',
                },
                '485': {
                    name: '额济纳旗',
                },
            },
        },
    },
    '486': {
        '487': {
            name: '沈阳',
            area: {
                '488': {
                    name: '和平区',
                },
                '489': {
                    name: '沈河区',
                },
                '490': {
                    name: '大东区',
                },
                '491': {
                    name: '皇姑区',
                },
                '492': {
                    name: '铁西区',
                },
                '493': {
                    name: '苏家屯区',
                },
                '494': {
                    name: '东陵区',
                },
                '495': {
                    name: '沈北新区',
                },
                '496': {
                    name: '于洪区',
                },
                '497': {
                    name: '辽中县',
                },
                '498': {
                    name: '康平县',
                },
                '499': {
                    name: '法库县',
                },
                '500': {
                    name: '新民',
                },
            },
        },
        '501': {
            name: '大连',
            area: {
                '502': {
                    name: '中山区',
                },
                '503': {
                    name: '西岗区',
                },
                '504': {
                    name: '沙河口区',
                },
                '505': {
                    name: '甘井子区',
                },
                '506': {
                    name: '旅顺口区',
                },
                '507': {
                    name: '金州区',
                },
                '508': {
                    name: '长海县',
                },
                '509': {
                    name: '瓦房店',
                },
                '510': {
                    name: '普兰店',
                },
                '511': {
                    name: '庄河',
                },
            },
        },
        '512': {
            name: '鞍山',
            area: {
                '513': {
                    name: '铁东区',
                },
                '514': {
                    name: '铁西区',
                },
                '515': {
                    name: '立山区',
                },
                '516': {
                    name: '千山区',
                },
                '517': {
                    name: '台安县',
                },
                '518': {
                    name: '岫岩',
                },
                '519': {
                    name: '海城',
                },
            },
        },
        '520': {
            name: '抚顺',
            area: {
                '521': {
                    name: '新抚区',
                },
                '522': {
                    name: '东洲区',
                },
                '523': {
                    name: '望花区',
                },
                '524': {
                    name: '顺城区',
                },
                '525': {
                    name: '抚顺县',
                },
                '526': {
                    name: '新宾',
                },
                '527': {
                    name: '清原',
                },
            },
        },
        '528': {
            name: '本溪',
            area: {
                '529': {
                    name: '平山区',
                },
                '530': {
                    name: '溪湖区',
                },
                '531': {
                    name: '明山区',
                },
                '532': {
                    name: '南芬区',
                },
                '533': {
                    name: '本溪',
                },
                '534': {
                    name: '桓仁',
                },
            },
        },
        '535': {
            name: '丹东',
            area: {
                '536': {
                    name: '元宝区',
                },
                '537': {
                    name: '振兴区',
                },
                '538': {
                    name: '振安区',
                },
                '539': {
                    name: '宽甸',
                },
                '540': {
                    name: '东港',
                },
                '541': {
                    name: '凤城',
                },
            },
        },
        '542': {
            name: '锦州',
            area: {
                '543': {
                    name: '古塔区',
                },
                '544': {
                    name: '凌河区',
                },
                '545': {
                    name: '太和区',
                },
                '546': {
                    name: '黑山县',
                },
                '547': {
                    name: '义县',
                },
                '548': {
                    name: '凌海',
                },
                '549': {
                    name: '北镇',
                },
            },
        },
        '550': {
            name: '营口',
            area: {
                '551': {
                    name: '站前区',
                },
                '552': {
                    name: '西市区',
                },
                '553': {
                    name: '鲅鱼圈区',
                },
                '554': {
                    name: '老边区',
                },
                '555': {
                    name: '盖州',
                },
                '556': {
                    name: '大石桥',
                },
            },
        },
        '557': {
            name: '阜新',
            area: {
                '558': {
                    name: '海州区',
                },
                '559': {
                    name: '新邱区',
                },
                '560': {
                    name: '太平区',
                },
                '561': {
                    name: '清河门区',
                },
                '562': {
                    name: '细河区',
                },
                '563': {
                    name: '阜新',
                },
                '564': {
                    name: '彰武县',
                },
            },
        },
        '565': {
            name: '辽阳',
            area: {
                '566': {
                    name: '白塔区',
                },
                '567': {
                    name: '文圣区',
                },
                '568': {
                    name: '宏伟区',
                },
                '569': {
                    name: '弓长岭区',
                },
                '570': {
                    name: '太子河区',
                },
                '571': {
                    name: '辽阳县',
                },
                '572': {
                    name: '灯塔',
                },
            },
        },
        '573': {
            name: '盘锦',
            area: {
                '574': {
                    name: '双台子区',
                },
                '575': {
                    name: '兴隆台区',
                },
                '576': {
                    name: '大洼县',
                },
                '577': {
                    name: '盘山县',
                },
            },
        },
        '578': {
            name: '铁岭',
            area: {
                '579': {
                    name: '银州区',
                },
                '580': {
                    name: '清河区',
                },
                '581': {
                    name: '铁岭县',
                },
                '582': {
                    name: '西丰县',
                },
                '583': {
                    name: '昌图县',
                },
                '584': {
                    name: '调兵山',
                },
                '585': {
                    name: '开原',
                },
            },
        },
        '586': {
            name: '朝阳',
            area: {
                '587': {
                    name: '双塔区',
                },
                '588': {
                    name: '龙城区',
                },
                '589': {
                    name: '朝阳县',
                },
                '590': {
                    name: '建平县',
                },
                '591': {
                    name: '喀喇沁',
                },
                '592': {
                    name: '北票',
                },
                '593': {
                    name: '凌源',
                },
            },
        },
        '594': {
            name: '葫芦岛',
            area: {
                '595': {
                    name: '连山区',
                },
                '596': {
                    name: '龙港区',
                },
                '597': {
                    name: '南票区',
                },
                '598': {
                    name: '绥中县',
                },
                '599': {
                    name: '建昌县',
                },
                '600': {
                    name: '兴城',
                },
            },
        },
    },
    '601': {
        '602': {
            name: '长春',
            area: {
                '603': {
                    name: '南关区',
                },
                '604': {
                    name: '宽城区',
                },
                '605': {
                    name: '朝阳区',
                },
                '606': {
                    name: '二道区',
                },
                '607': {
                    name: '绿园区',
                },
                '608': {
                    name: '双阳区',
                },
                '609': {
                    name: '农安县',
                },
                '610': {
                    name: '九台',
                },
                '611': {
                    name: '榆树',
                },
                '612': {
                    name: '德惠',
                },
            },
        },
        '613': {
            name: '吉林',
            area: {
                '614': {
                    name: '昌邑区',
                },
                '615': {
                    name: '龙潭区',
                },
                '616': {
                    name: '船营区',
                },
                '617': {
                    name: '丰满区',
                },
                '618': {
                    name: '永吉县',
                },
                '619': {
                    name: '蛟河',
                },
                '620': {
                    name: '桦甸',
                },
                '621': {
                    name: '舒兰',
                },
                '622': {
                    name: '磐石',
                },
            },
        },
        '623': {
            name: '四平',
            area: {
                '624': {
                    name: '铁西区',
                },
                '625': {
                    name: '铁东区',
                },
                '626': {
                    name: '梨树县',
                },
                '627': {
                    name: '伊通',
                },
                '628': {
                    name: '公主岭',
                },
                '629': {
                    name: '双辽',
                },
            },
        },
        '630': {
            name: '辽源',
            area: {
                '631': {
                    name: '龙山区',
                },
                '632': {
                    name: '西安区',
                },
                '633': {
                    name: '东丰县',
                },
                '634': {
                    name: '东辽县',
                },
            },
        },
        '635': {
            name: '通化',
            area: {
                '636': {
                    name: '东昌区',
                },
                '637': {
                    name: '二道江区',
                },
                '638': {
                    name: '通化县',
                },
                '639': {
                    name: '辉南县',
                },
                '640': {
                    name: '柳河县',
                },
                '641': {
                    name: '梅河口',
                },
                '642': {
                    name: '集安',
                },
            },
        },
        '643': {
            name: '白山',
            area: {
                '644': {
                    name: '浑江区',
                },
                '645': {
                    name: '江源区',
                },
                '646': {
                    name: '抚松县',
                },
                '647': {
                    name: '靖宇县',
                },
                '648': {
                    name: '长白',
                },
                '649': {
                    name: '临江',
                },
            },
        },
        '650': {
            name: '松原',
            area: {
                '651': {
                    name: '宁江区',
                },
                '652': {
                    name: '前郭尔罗斯',
                },
                '653': {
                    name: '长岭县',
                },
                '654': {
                    name: '乾安县',
                },
                '655': {
                    name: '扶余县',
                },
            },
        },
        '656': {
            name: '白城',
            area: {
                '657': {
                    name: '洮北区',
                },
                '658': {
                    name: '镇赉县',
                },
                '659': {
                    name: '通榆县',
                },
                '660': {
                    name: '洮南',
                },
                '661': {
                    name: '大安',
                },
            },
        },
        '662': {
            name: '延边',
            area: {
                '663': {
                    name: '延吉',
                },
                '664': {
                    name: '图们',
                },
                '665': {
                    name: '敦化',
                },
                '666': {
                    name: '珲春',
                },
                '667': {
                    name: '龙井',
                },
                '668': {
                    name: '和龙',
                },
                '669': {
                    name: '汪清县',
                },
                '670': {
                    name: '安图县',
                },
            },
        },
    },
    '671': {
        '672': {
            name: '哈尔滨',
            area: {
                '673': {
                    name: '道里区',
                },
                '674': {
                    name: '南岗区',
                },
                '675': {
                    name: '道外区',
                },
                '676': {
                    name: '平房区',
                },
                '677': {
                    name: '松北区',
                },
                '678': {
                    name: '香坊区',
                },
                '679': {
                    name: '呼兰区',
                },
                '680': {
                    name: '阿城区',
                },
                '681': {
                    name: '依兰县',
                },
                '682': {
                    name: '方正县',
                },
                '683': {
                    name: '宾县',
                },
                '684': {
                    name: '巴彦县',
                },
                '685': {
                    name: '木兰县',
                },
                '686': {
                    name: '通河县',
                },
                '687': {
                    name: '延寿县',
                },
                '688': {
                    name: '双城',
                },
                '689': {
                    name: '尚志',
                },
                '690': {
                    name: '五常',
                },
            },
        },
        '691': {
            name: '齐齐哈尔',
            area: {
                '692': {
                    name: '龙沙区',
                },
                '693': {
                    name: '建华区',
                },
                '694': {
                    name: '铁锋区',
                },
                '695': {
                    name: '昂昂溪区',
                },
                '696': {
                    name: '富拉尔基区',
                },
                '697': {
                    name: '碾子山区',
                },
                '698': {
                    name: '梅里斯达',
                },
                '699': {
                    name: '龙江县',
                },
                '700': {
                    name: '依安县',
                },
                '701': {
                    name: '泰来县',
                },
                '702': {
                    name: '甘南县',
                },
                '703': {
                    name: '富裕县',
                },
                '704': {
                    name: '克山县',
                },
                '705': {
                    name: '克东县',
                },
                '706': {
                    name: '拜泉县',
                },
                '707': {
                    name: '讷河',
                },
            },
        },
        '708': {
            name: '鸡西',
            area: {
                '709': {
                    name: '鸡冠区',
                },
                '710': {
                    name: '恒山区',
                },
                '711': {
                    name: '滴道区',
                },
                '712': {
                    name: '梨树区',
                },
                '713': {
                    name: '城子河区',
                },
                '714': {
                    name: '麻山区',
                },
                '715': {
                    name: '鸡东县',
                },
                '716': {
                    name: '虎林',
                },
                '717': {
                    name: '密山',
                },
            },
        },
        '718': {
            name: '鹤岗',
            area: {
                '719': {
                    name: '向阳区',
                },
                '720': {
                    name: '工农区',
                },
                '721': {
                    name: '南山区',
                },
                '722': {
                    name: '兴安区',
                },
                '723': {
                    name: '东山区',
                },
                '724': {
                    name: '兴山区',
                },
                '725': {
                    name: '萝北县',
                },
                '726': {
                    name: '绥滨县',
                },
            },
        },
        '727': {
            name: '双鸭山',
            area: {
                '728': {
                    name: '尖山区',
                },
                '729': {
                    name: '岭东区',
                },
                '730': {
                    name: '四方台区',
                },
                '731': {
                    name: '宝山区',
                },
                '732': {
                    name: '集贤县',
                },
                '733': {
                    name: '友谊县',
                },
                '734': {
                    name: '宝清县',
                },
                '735': {
                    name: '饶河县',
                },
            },
        },
        '736': {
            name: '大庆',
            area: {
                '737': {
                    name: '萨尔图区',
                },
                '738': {
                    name: '龙凤区',
                },
                '739': {
                    name: '让胡路区',
                },
                '740': {
                    name: '红岗区',
                },
                '741': {
                    name: '大同区',
                },
                '742': {
                    name: '肇州县',
                },
                '743': {
                    name: '肇源县',
                },
                '744': {
                    name: '林甸县',
                },
                '745': {
                    name: '杜尔伯特',
                },
            },
        },
        '746': {
            name: '伊春',
            area: {
                '747': {
                    name: '伊春区',
                },
                '748': {
                    name: '南岔区',
                },
                '749': {
                    name: '友好区',
                },
                '750': {
                    name: '西林区',
                },
                '751': {
                    name: '翠峦区',
                },
                '752': {
                    name: '新青区',
                },
                '753': {
                    name: '美溪区',
                },
                '754': {
                    name: '金山屯区',
                },
                '755': {
                    name: '五营区',
                },
                '756': {
                    name: '乌马河区',
                },
                '757': {
                    name: '汤旺河区',
                },
                '758': {
                    name: '带岭区',
                },
                '759': {
                    name: '乌伊岭区',
                },
                '760': {
                    name: '红星区',
                },
                '761': {
                    name: '上甘岭区',
                },
                '762': {
                    name: '嘉荫县',
                },
                '763': {
                    name: '铁力',
                },
            },
        },
        '764': {
            name: '佳木斯',
            area: {
                '765': {
                    name: '向阳区',
                },
                '766': {
                    name: '前进区',
                },
                '767': {
                    name: '东风区',
                },
                '768': {
                    name: '郊区',
                },
                '769': {
                    name: '桦南县',
                },
                '770': {
                    name: '桦川县',
                },
                '771': {
                    name: '汤原县',
                },
                '772': {
                    name: '抚远县',
                },
                '773': {
                    name: '同江',
                },
                '774': {
                    name: '富锦',
                },
            },
        },
        '775': {
            name: '七台河',
            area: {
                '776': {
                    name: '新兴区',
                },
                '777': {
                    name: '桃山区',
                },
                '778': {
                    name: '茄子河区',
                },
                '779': {
                    name: '勃利县',
                },
            },
        },
        '780': {
            name: '牡丹江',
            area: {
                '781': {
                    name: '东安区',
                },
                '782': {
                    name: '阳明区',
                },
                '783': {
                    name: '爱民区',
                },
                '784': {
                    name: '西安区',
                },
                '785': {
                    name: '东宁县',
                },
                '786': {
                    name: '林口县',
                },
                '787': {
                    name: '绥芬河',
                },
                '788': {
                    name: '海林',
                },
                '789': {
                    name: '宁安',
                },
                '790': {
                    name: '穆棱',
                },
            },
        },
        '791': {
            name: '黑河',
            area: {
                '792': {
                    name: '爱辉区',
                },
                '793': {
                    name: '嫩江县',
                },
                '794': {
                    name: '逊克县',
                },
                '795': {
                    name: '孙吴县',
                },
                '796': {
                    name: '北安',
                },
                '797': {
                    name: '五大连池',
                },
            },
        },
        '798': {
            name: '绥化',
            area: {
                '799': {
                    name: '北林区',
                },
                '800': {
                    name: '望奎县',
                },
                '801': {
                    name: '兰西县',
                },
                '802': {
                    name: '青冈县',
                },
                '803': {
                    name: '庆安县',
                },
                '804': {
                    name: '明水县',
                },
                '805': {
                    name: '绥棱县',
                },
                '806': {
                    name: '安达',
                },
                '807': {
                    name: '肇东',
                },
                '808': {
                    name: '海伦',
                },
            },
        },
        '809': {
            name: '大兴安岭',
            area: {
                '810': {
                    name: '呼玛县',
                },
                '811': {
                    name: '塔河县',
                },
                '812': {
                    name: '漠河县',
                },
            },
        },
    },
    '813': {
        '814': {
            name: '上海',
            area: {
                '815': {
                    name: '黄浦区',
                },
                '816': {
                    name: '徐汇区',
                },
                '817': {
                    name: '长宁区',
                },
                '818': {
                    name: '静安区',
                },
                '819': {
                    name: '普陀区',
                },
                '820': {
                    name: '闸北区',
                },
                '821': {
                    name: '虹口区',
                },
                '822': {
                    name: '杨浦区',
                },
                '823': {
                    name: '闵行区',
                },
                '824': {
                    name: '宝山区',
                },
                '825': {
                    name: '嘉定区',
                },
                '826': {
                    name: '浦东新区',
                },
                '827': {
                    name: '金山区',
                },
                '828': {
                    name: '松江区',
                },
                '829': {
                    name: '青浦区',
                },
                '830': {
                    name: '奉贤区',
                },
                '831': {
                    name: '崇明县',
                },
            },
        },
    },
    '832': {
        '833': {
            name: '南京',
            area: {
                '834': {
                    name: '玄武区',
                },
                '835': {
                    name: '白下区',
                },
                '836': {
                    name: '秦淮区',
                },
                '837': {
                    name: '建邺区',
                },
                '838': {
                    name: '鼓楼区',
                },
                '839': {
                    name: '下关区',
                },
                '840': {
                    name: '浦口区',
                },
                '841': {
                    name: '栖霞区',
                },
                '842': {
                    name: '雨花台区',
                },
                '843': {
                    name: '江宁区',
                },
                '844': {
                    name: '六合区',
                },
                '845': {
                    name: '溧水县',
                },
                '846': {
                    name: '高淳县',
                },
            },
        },
        '847': {
            name: '无锡',
            area: {
                '848': {
                    name: '崇安区',
                },
                '849': {
                    name: '南长区',
                },
                '850': {
                    name: '北塘区',
                },
                '851': {
                    name: '锡山区',
                },
                '852': {
                    name: '惠山区',
                },
                '853': {
                    name: '滨湖区',
                },
                '854': {
                    name: '江阴',
                },
                '855': {
                    name: '宜兴',
                },
            },
        },
        '856': {
            name: '徐州',
            area: {
                '857': {
                    name: '鼓楼区',
                },
                '858': {
                    name: '云龙区',
                },
                '859': {
                    name: '贾汪区',
                },
                '860': {
                    name: '泉山区',
                },
                '861': {
                    name: '铜山区',
                },
                '862': {
                    name: '丰县',
                },
                '863': {
                    name: '沛县',
                },
                '864': {
                    name: '睢宁县',
                },
                '865': {
                    name: '新沂',
                },
                '866': {
                    name: '邳州',
                },
            },
        },
        '867': {
            name: '常州',
            area: {
                '868': {
                    name: '天宁区',
                },
                '869': {
                    name: '钟楼区',
                },
                '870': {
                    name: '戚墅堰区',
                },
                '871': {
                    name: '新北区',
                },
                '872': {
                    name: '武进区',
                },
                '873': {
                    name: '溧阳',
                },
                '874': {
                    name: '金坛',
                },
            },
        },
        '875': {
            name: '苏州',
            area: {
                '876': {
                    name: '虎丘区',
                },
                '877': {
                    name: '吴中区',
                },
                '878': {
                    name: '相城区',
                },
                '879': {
                    name: '姑苏区',
                },
                '880': {
                    name: '吴江区',
                },
                '881': {
                    name: '常熟',
                },
                '882': {
                    name: '张家港',
                },
                '883': {
                    name: '昆山',
                },
                '884': {
                    name: '太仓',
                },
            },
        },
        '885': {
            name: '南通',
            area: {
                '886': {
                    name: '崇川区',
                },
                '887': {
                    name: '港闸区',
                },
                '888': {
                    name: '通州区',
                },
                '889': {
                    name: '海安县',
                },
                '890': {
                    name: '如东县',
                },
                '891': {
                    name: '启东',
                },
                '892': {
                    name: '如皋',
                },
                '893': {
                    name: '海门',
                },
            },
        },
        '894': {
            name: '连云港',
            area: {
                '895': {
                    name: '连云区',
                },
                '896': {
                    name: '新浦区',
                },
                '897': {
                    name: '海州区',
                },
                '898': {
                    name: '赣榆县',
                },
                '899': {
                    name: '东海县',
                },
                '900': {
                    name: '灌云县',
                },
                '901': {
                    name: '灌南县',
                },
            },
        },
        '902': {
            name: '淮安',
            area: {
                '903': {
                    name: '清河区',
                },
                '904': {
                    name: '淮安区',
                },
                '905': {
                    name: '淮阴区',
                },
                '906': {
                    name: '清浦区',
                },
                '907': {
                    name: '涟水县',
                },
                '908': {
                    name: '洪泽县',
                },
                '909': {
                    name: '盱眙县',
                },
                '910': {
                    name: '金湖县',
                },
            },
        },
        '911': {
            name: '盐城',
            area: {
                '912': {
                    name: '亭湖区',
                },
                '913': {
                    name: '盐都区',
                },
                '914': {
                    name: '响水县',
                },
                '915': {
                    name: '滨海县',
                },
                '916': {
                    name: '阜宁县',
                },
                '917': {
                    name: '射阳县',
                },
                '918': {
                    name: '建湖县',
                },
                '919': {
                    name: '东台',
                },
                '920': {
                    name: '大丰',
                },
            },
        },
        '921': {
            name: '扬州',
            area: {
                '922': {
                    name: '广陵区',
                },
                '923': {
                    name: '邗江区',
                },
                '924': {
                    name: '江都区',
                },
                '925': {
                    name: '宝应县',
                },
                '926': {
                    name: '仪征',
                },
                '927': {
                    name: '高邮',
                },
            },
        },
        '928': {
            name: '镇江',
            area: {
                '929': {
                    name: '京口区',
                },
                '930': {
                    name: '润州区',
                },
                '931': {
                    name: '丹徒区',
                },
                '932': {
                    name: '丹阳',
                },
                '933': {
                    name: '扬中',
                },
                '934': {
                    name: '句容',
                },
            },
        },
        '935': {
            name: '泰州',
            area: {
                '936': {
                    name: '海陵区',
                },
                '937': {
                    name: '高港区',
                },
                '938': {
                    name: '兴化',
                },
                '939': {
                    name: '靖江',
                },
                '940': {
                    name: '泰兴',
                },
                '941': {
                    name: '姜堰',
                },
            },
        },
        '942': {
            name: '宿迁',
            area: {
                '943': {
                    name: '宿城区',
                },
                '944': {
                    name: '宿豫区',
                },
                '945': {
                    name: '沭阳县',
                },
                '946': {
                    name: '泗阳县',
                },
                '947': {
                    name: '泗洪县',
                },
            },
        },
    },
    '948': {
        '949': {
            name: '杭州',
            area: {
                '950': {
                    name: '上城区',
                },
                '951': {
                    name: '下城区',
                },
                '952': {
                    name: '江干区',
                },
                '953': {
                    name: '拱墅区',
                },
                '954': {
                    name: '西湖区',
                },
                '955': {
                    name: '滨江区',
                },
                '956': {
                    name: '萧山区',
                },
                '957': {
                    name: '余杭区',
                },
                '958': {
                    name: '桐庐县',
                },
                '959': {
                    name: '淳安县',
                },
                '960': {
                    name: '建德',
                },
                '961': {
                    name: '富阳',
                },
                '962': {
                    name: '临安',
                },
            },
        },
        '963': {
            name: '宁波',
            area: {
                '964': {
                    name: '海曙区',
                },
                '965': {
                    name: '江东区',
                },
                '966': {
                    name: '江北区',
                },
                '967': {
                    name: '北仑区',
                },
                '968': {
                    name: '镇海区',
                },
                '969': {
                    name: '鄞州区',
                },
                '970': {
                    name: '象山县',
                },
                '971': {
                    name: '宁海县',
                },
                '972': {
                    name: '余姚',
                },
                '973': {
                    name: '慈溪',
                },
                '974': {
                    name: '奉化',
                },
            },
        },
        '975': {
            name: '温州',
            area: {
                '976': {
                    name: '鹿城区',
                },
                '977': {
                    name: '龙湾区',
                },
                '978': {
                    name: '瓯海区',
                },
                '979': {
                    name: '洞头县',
                },
                '980': {
                    name: '永嘉县',
                },
                '981': {
                    name: '平阳县',
                },
                '982': {
                    name: '苍南县',
                },
                '983': {
                    name: '文成县',
                },
                '984': {
                    name: '泰顺县',
                },
                '985': {
                    name: '瑞安',
                },
                '986': {
                    name: '乐清',
                },
            },
        },
        '987': {
            name: '嘉兴',
            area: {
                '988': {
                    name: '南湖区',
                },
                '989': {
                    name: '秀洲区',
                },
                '990': {
                    name: '嘉善县',
                },
                '991': {
                    name: '海盐县',
                },
                '992': {
                    name: '海宁',
                },
                '993': {
                    name: '平湖',
                },
                '994': {
                    name: '桐乡',
                },
            },
        },
        '995': {
            name: '湖州',
            area: {
                '996': {
                    name: '吴兴区',
                },
                '997': {
                    name: '南浔区',
                },
                '998': {
                    name: '德清县',
                },
                '999': {
                    name: '长兴县',
                },
                '1000': {
                    name: '安吉县',
                },
            },
        },
        '1001': {
            name: '绍兴',
            area: {
                '1002': {
                    name: '越城区',
                },
                '1003': {
                    name: '绍兴县',
                },
                '1004': {
                    name: '新昌县',
                },
                '1005': {
                    name: '诸暨',
                },
                '1006': {
                    name: '上虞',
                },
                '1007': {
                    name: '嵊州',
                },
            },
        },
        '1008': {
            name: '金华',
            area: {
                '1009': {
                    name: '婺城区',
                },
                '1010': {
                    name: '金东区',
                },
                '1011': {
                    name: '武义县',
                },
                '1012': {
                    name: '浦江县',
                },
                '1013': {
                    name: '磐安县',
                },
                '1014': {
                    name: '兰溪',
                },
                '1015': {
                    name: '义乌',
                },
                '1016': {
                    name: '东阳',
                },
                '1017': {
                    name: '永康',
                },
            },
        },
        '1018': {
            name: '衢州',
            area: {
                '1019': {
                    name: '柯城区',
                },
                '1020': {
                    name: '衢江区',
                },
                '1021': {
                    name: '常山县',
                },
                '1022': {
                    name: '开化县',
                },
                '1023': {
                    name: '龙游县',
                },
                '1024': {
                    name: '江山',
                },
            },
        },
        '1025': {
            name: '舟山',
            area: {
                '1026': {
                    name: '定海区',
                },
                '1027': {
                    name: '普陀区',
                },
                '1028': {
                    name: '岱山县',
                },
                '1029': {
                    name: '嵊泗县',
                },
            },
        },
        '1030': {
            name: '台州',
            area: {
                '1031': {
                    name: '椒江区',
                },
                '1032': {
                    name: '黄岩区',
                },
                '1033': {
                    name: '路桥区',
                },
                '1034': {
                    name: '玉环县',
                },
                '1035': {
                    name: '三门县',
                },
                '1036': {
                    name: '天台县',
                },
                '1037': {
                    name: '仙居县',
                },
                '1038': {
                    name: '温岭',
                },
                '1039': {
                    name: '临海',
                },
            },
        },
        '1040': {
            name: '丽水',
            area: {
                '1041': {
                    name: '莲都区',
                },
                '1042': {
                    name: '青田县',
                },
                '1043': {
                    name: '缙云县',
                },
                '1044': {
                    name: '遂昌县',
                },
                '1045': {
                    name: '松阳县',
                },
                '1046': {
                    name: '云和县',
                },
                '1047': {
                    name: '庆元县',
                },
                '1048': {
                    name: '景宁',
                },
                '1049': {
                    name: '龙泉',
                },
            },
        },
    },
    '1050': {
        '1051': {
            name: '合肥',
            area: {
                '1052': {
                    name: '瑶海区',
                },
                '1053': {
                    name: '庐阳区',
                },
                '1054': {
                    name: '蜀山区',
                },
                '1055': {
                    name: '包河区',
                },
                '1056': {
                    name: '长丰县',
                },
                '1057': {
                    name: '肥东县',
                },
                '1058': {
                    name: '肥西县',
                },
                '1059': {
                    name: '庐江县',
                },
                '1060': {
                    name: '巢湖',
                },
            },
        },
        '1061': {
            name: '芜湖',
            area: {
                '1062': {
                    name: '镜湖区',
                },
                '1063': {
                    name: '弋江区',
                },
                '1064': {
                    name: '鸠江区',
                },
                '1065': {
                    name: '三山区',
                },
                '1066': {
                    name: '芜湖县',
                },
                '1067': {
                    name: '繁昌县',
                },
                '1068': {
                    name: '南陵县',
                },
                '1069': {
                    name: '无为县',
                },
            },
        },
        '1070': {
            name: '蚌埠',
            area: {
                '1071': {
                    name: '龙子湖区',
                },
                '1072': {
                    name: '蚌山区',
                },
                '1073': {
                    name: '禹会区',
                },
                '1074': {
                    name: '淮上区',
                },
                '1075': {
                    name: '怀远县',
                },
                '1076': {
                    name: '五河县',
                },
                '1077': {
                    name: '固镇县',
                },
            },
        },
        '1078': {
            name: '淮南',
            area: {
                '1079': {
                    name: '大通区',
                },
                '1080': {
                    name: '田家庵区',
                },
                '1081': {
                    name: '谢家集区',
                },
                '1082': {
                    name: '八公山区',
                },
                '1083': {
                    name: '潘集区',
                },
                '1084': {
                    name: '凤台县',
                },
            },
        },
        '1085': {
            name: '马鞍山',
            area: {
                '1086': {
                    name: '花山区',
                },
                '1087': {
                    name: '雨山区',
                },
                '1088': {
                    name: '博望区',
                },
                '1089': {
                    name: '当涂县',
                },
                '1090': {
                    name: '含山县',
                },
                '1091': {
                    name: '和县',
                },
            },
        },
        '1092': {
            name: '淮北',
            area: {
                '1093': {
                    name: '杜集区',
                },
                '1094': {
                    name: '相山区',
                },
                '1095': {
                    name: '烈山区',
                },
                '1096': {
                    name: '濉溪县',
                },
            },
        },
        '1097': {
            name: '铜陵',
            area: {
                '1098': {
                    name: '铜官山区',
                },
                '1099': {
                    name: '狮子山区',
                },
                '1100': {
                    name: '郊区',
                },
                '1101': {
                    name: '铜陵县',
                },
            },
        },
        '1102': {
            name: '安庆',
            area: {
                '1103': {
                    name: '迎江区',
                },
                '1104': {
                    name: '大观区',
                },
                '1105': {
                    name: '宜秀区',
                },
                '1106': {
                    name: '怀宁县',
                },
                '1107': {
                    name: '枞阳县',
                },
                '1108': {
                    name: '潜山县',
                },
                '1109': {
                    name: '太湖县',
                },
                '1110': {
                    name: '宿松县',
                },
                '1111': {
                    name: '望江县',
                },
                '1112': {
                    name: '岳西县',
                },
                '1113': {
                    name: '桐城',
                },
            },
        },
        '1114': {
            name: '黄山',
            area: {
                '1115': {
                    name: '屯溪区',
                },
                '1116': {
                    name: '黄山区',
                },
                '1117': {
                    name: '徽州区',
                },
                '1118': {
                    name: '歙县',
                },
                '1119': {
                    name: '休宁县',
                },
                '1120': {
                    name: '黟县',
                },
                '1121': {
                    name: '祁门县',
                },
            },
        },
        '1122': {
            name: '滁州',
            area: {
                '1123': {
                    name: '琅琊区',
                },
                '1124': {
                    name: '南谯区',
                },
                '1125': {
                    name: '来安县',
                },
                '1126': {
                    name: '全椒县',
                },
                '1127': {
                    name: '定远县',
                },
                '1128': {
                    name: '凤阳县',
                },
                '1129': {
                    name: '天长',
                },
                '1130': {
                    name: '明光',
                },
            },
        },
        '1131': {
            name: '阜阳',
            area: {
                '1132': {
                    name: '颍州区',
                },
                '1133': {
                    name: '颍东区',
                },
                '1134': {
                    name: '颍泉区',
                },
                '1135': {
                    name: '临泉县',
                },
                '1136': {
                    name: '太和县',
                },
                '1137': {
                    name: '阜南县',
                },
                '1138': {
                    name: '颍上县',
                },
                '1139': {
                    name: '界首',
                },
            },
        },
        '1140': {
            name: '宿州',
            area: {
                '1141': {
                    name: '埇桥区',
                },
                '1142': {
                    name: '砀山县',
                },
                '1143': {
                    name: '萧县',
                },
                '1144': {
                    name: '灵璧县',
                },
                '1145': {
                    name: '泗县',
                },
            },
        },
        '1146': {
            name: '六安',
            area: {
                '1147': {
                    name: '金安区',
                },
                '1148': {
                    name: '裕安区',
                },
                '1149': {
                    name: '寿县',
                },
                '1150': {
                    name: '霍邱县',
                },
                '1151': {
                    name: '舒城县',
                },
                '1152': {
                    name: '金寨县',
                },
                '1153': {
                    name: '霍山县',
                },
            },
        },
        '1154': {
            name: '亳州',
            area: {
                '1155': {
                    name: '谯城区',
                },
                '1156': {
                    name: '涡阳县',
                },
                '1157': {
                    name: '蒙城县',
                },
                '1158': {
                    name: '利辛县',
                },
            },
        },
        '1159': {
            name: '池州',
            area: {
                '1160': {
                    name: '贵池区',
                },
                '1161': {
                    name: '东至县',
                },
                '1162': {
                    name: '石台县',
                },
                '1163': {
                    name: '青阳县',
                },
            },
        },
        '1164': {
            name: '宣城',
            area: {
                '1165': {
                    name: '宣州区',
                },
                '1166': {
                    name: '郎溪县',
                },
                '1167': {
                    name: '广德县',
                },
                '1168': {
                    name: '泾县',
                },
                '1169': {
                    name: '绩溪县',
                },
                '1170': {
                    name: '旌德县',
                },
                '1171': {
                    name: '宁国',
                },
            },
        },
    },
    '1172': {
        '1173': {
            name: '福州',
            area: {
                '1174': {
                    name: '鼓楼区',
                },
                '1175': {
                    name: '台江区',
                },
                '1176': {
                    name: '仓山区',
                },
                '1177': {
                    name: '马尾区',
                },
                '1178': {
                    name: '晋安区',
                },
                '1179': {
                    name: '闽侯县',
                },
                '1180': {
                    name: '连江县',
                },
                '1181': {
                    name: '罗源县',
                },
                '1182': {
                    name: '闽清县',
                },
                '1183': {
                    name: '永泰县',
                },
                '1184': {
                    name: '平潭县',
                },
                '1185': {
                    name: '福清',
                },
                '1186': {
                    name: '长乐',
                },
            },
        },
        '1187': {
            name: '厦门',
            area: {
                '1188': {
                    name: '思明区',
                },
                '1189': {
                    name: '海沧区',
                },
                '1190': {
                    name: '湖里区',
                },
                '1191': {
                    name: '集美区',
                },
                '1192': {
                    name: '同安区',
                },
                '1193': {
                    name: '翔安区',
                },
            },
        },
        '1194': {
            name: '莆田',
            area: {
                '1195': {
                    name: '城厢区',
                },
                '1196': {
                    name: '涵江区',
                },
                '1197': {
                    name: '荔城区',
                },
                '1198': {
                    name: '秀屿区',
                },
                '1199': {
                    name: '仙游县',
                },
            },
        },
        '1200': {
            name: '三明',
            area: {
                '1201': {
                    name: '梅列区',
                },
                '1202': {
                    name: '三元区',
                },
                '1203': {
                    name: '明溪县',
                },
                '1204': {
                    name: '清流县',
                },
                '1205': {
                    name: '宁化县',
                },
                '1206': {
                    name: '大田县',
                },
                '1207': {
                    name: '尤溪县',
                },
                '1208': {
                    name: '沙县',
                },
                '1209': {
                    name: '将乐县',
                },
                '1210': {
                    name: '泰宁县',
                },
                '1211': {
                    name: '建宁县',
                },
                '1212': {
                    name: '永安',
                },
            },
        },
        '1213': {
            name: '泉州',
            area: {
                '1214': {
                    name: '鲤城区',
                },
                '1215': {
                    name: '丰泽区',
                },
                '1216': {
                    name: '洛江区',
                },
                '1217': {
                    name: '泉港区',
                },
                '1218': {
                    name: '惠安县',
                },
                '1219': {
                    name: '安溪县',
                },
                '1220': {
                    name: '永春县',
                },
                '1221': {
                    name: '德化县',
                },
                '1222': {
                    name: '金门县',
                },
                '1223': {
                    name: '石狮',
                },
                '1224': {
                    name: '晋江',
                },
                '1225': {
                    name: '南安',
                },
            },
        },
        '1226': {
            name: '漳州',
            area: {
                '1227': {
                    name: '芗城区',
                },
                '1228': {
                    name: '龙文区',
                },
                '1229': {
                    name: '云霄县',
                },
                '1230': {
                    name: '漳浦县',
                },
                '1231': {
                    name: '诏安县',
                },
                '1232': {
                    name: '长泰县',
                },
                '1233': {
                    name: '东山县',
                },
                '1234': {
                    name: '南靖县',
                },
                '1235': {
                    name: '平和县',
                },
                '1236': {
                    name: '华安县',
                },
                '1237': {
                    name: '龙海',
                },
            },
        },
        '1238': {
            name: '南平',
            area: {
                '1239': {
                    name: '延平区',
                },
                '1240': {
                    name: '顺昌县',
                },
                '1241': {
                    name: '浦城县',
                },
                '1242': {
                    name: '光泽县',
                },
                '1243': {
                    name: '松溪县',
                },
                '1244': {
                    name: '政和县',
                },
                '1245': {
                    name: '邵武',
                },
                '1246': {
                    name: '武夷山',
                },
                '1247': {
                    name: '建瓯',
                },
                '1248': {
                    name: '建阳',
                },
            },
        },
        '1249': {
            name: '龙岩',
            area: {
                '1250': {
                    name: '新罗区',
                },
                '1251': {
                    name: '长汀县',
                },
                '1252': {
                    name: '永定县',
                },
                '1253': {
                    name: '上杭县',
                },
                '1254': {
                    name: '武平县',
                },
                '1255': {
                    name: '连城县',
                },
                '1256': {
                    name: '漳平',
                },
            },
        },
        '1257': {
            name: '宁德',
            area: {
                '1258': {
                    name: '蕉城区',
                },
                '1259': {
                    name: '霞浦县',
                },
                '1260': {
                    name: '古田县',
                },
                '1261': {
                    name: '屏南县',
                },
                '1262': {
                    name: '寿宁县',
                },
                '1263': {
                    name: '周宁县',
                },
                '1264': {
                    name: '柘荣县',
                },
                '1265': {
                    name: '福安',
                },
                '1266': {
                    name: '福鼎',
                },
            },
        },
    },
    '1267': {
        '1268': {
            name: '南昌',
            area: {
                '1269': {
                    name: '东湖区',
                },
                '1270': {
                    name: '西湖区',
                },
                '1271': {
                    name: '青云谱区',
                },
                '1272': {
                    name: '湾里区',
                },
                '1273': {
                    name: '青山湖区',
                },
                '1274': {
                    name: '南昌县',
                },
                '1275': {
                    name: '新建县',
                },
                '1276': {
                    name: '安义县',
                },
                '1277': {
                    name: '进贤县',
                },
            },
        },
        '1278': {
            name: '景德镇',
            area: {
                '1279': {
                    name: '昌江区',
                },
                '1280': {
                    name: '珠山区',
                },
                '1281': {
                    name: '浮梁县',
                },
                '1282': {
                    name: '乐平',
                },
            },
        },
        '1283': {
            name: '萍乡',
            area: {
                '1284': {
                    name: '安源区',
                },
                '1285': {
                    name: '湘东区',
                },
                '1286': {
                    name: '莲花县',
                },
                '1287': {
                    name: '上栗县',
                },
                '1288': {
                    name: '芦溪县',
                },
            },
        },
        '1289': {
            name: '九江',
            area: {
                '1290': {
                    name: '庐山区',
                },
                '1291': {
                    name: '浔阳区',
                },
                '1292': {
                    name: '九江县',
                },
                '1293': {
                    name: '武宁县',
                },
                '1294': {
                    name: '修水县',
                },
                '1295': {
                    name: '永修县',
                },
                '1296': {
                    name: '德安县',
                },
                '1297': {
                    name: '星子县',
                },
                '1298': {
                    name: '都昌县',
                },
                '1299': {
                    name: '湖口县',
                },
                '1300': {
                    name: '彭泽县',
                },
                '1301': {
                    name: '瑞昌',
                },
                '1302': {
                    name: '共青城',
                },
            },
        },
        '1303': {
            name: '新余',
            area: {
                '1304': {
                    name: '渝水区',
                },
                '1305': {
                    name: '分宜县',
                },
            },
        },
        '1306': {
            name: '鹰潭',
            area: {
                '1307': {
                    name: '月湖区',
                },
                '1308': {
                    name: '余江县',
                },
                '1309': {
                    name: '贵溪',
                },
            },
        },
        '1310': {
            name: '赣州',
            area: {
                '1311': {
                    name: '章贡区',
                },
                '1312': {
                    name: '赣县',
                },
                '1313': {
                    name: '信丰县',
                },
                '1314': {
                    name: '大余县',
                },
                '1315': {
                    name: '上犹县',
                },
                '1316': {
                    name: '崇义县',
                },
                '1317': {
                    name: '安远县',
                },
                '1318': {
                    name: '龙南县',
                },
                '1319': {
                    name: '定南县',
                },
                '1320': {
                    name: '全南县',
                },
                '1321': {
                    name: '宁都县',
                },
                '1322': {
                    name: '于都县',
                },
                '1323': {
                    name: '兴国县',
                },
                '1324': {
                    name: '会昌县',
                },
                '1325': {
                    name: '寻乌县',
                },
                '1326': {
                    name: '石城县',
                },
                '1327': {
                    name: '瑞金',
                },
                '1328': {
                    name: '南康',
                },
            },
        },
        '1329': {
            name: '吉安',
            area: {
                '1330': {
                    name: '吉州区',
                },
                '1331': {
                    name: '青原区',
                },
                '1332': {
                    name: '吉安县',
                },
                '1333': {
                    name: '吉水县',
                },
                '1334': {
                    name: '峡江县',
                },
                '1335': {
                    name: '新干县',
                },
                '1336': {
                    name: '永丰县',
                },
                '1337': {
                    name: '泰和县',
                },
                '1338': {
                    name: '遂川县',
                },
                '1339': {
                    name: '万安县',
                },
                '1340': {
                    name: '安福县',
                },
                '1341': {
                    name: '永新县',
                },
                '1342': {
                    name: '井冈山',
                },
            },
        },
        '1343': {
            name: '宜春',
            area: {
                '1344': {
                    name: '袁州区',
                },
                '1345': {
                    name: '奉新县',
                },
                '1346': {
                    name: '万载县',
                },
                '1347': {
                    name: '上高县',
                },
                '1348': {
                    name: '宜丰县',
                },
                '1349': {
                    name: '靖安县',
                },
                '1350': {
                    name: '铜鼓县',
                },
                '1351': {
                    name: '丰城',
                },
                '1352': {
                    name: '樟树',
                },
                '1353': {
                    name: '高安',
                },
            },
        },
        '1354': {
            name: '抚州',
            area: {
                '1355': {
                    name: '临川区',
                },
                '1356': {
                    name: '南城县',
                },
                '1357': {
                    name: '黎川县',
                },
                '1358': {
                    name: '南丰县',
                },
                '1359': {
                    name: '崇仁县',
                },
                '1360': {
                    name: '乐安县',
                },
                '1361': {
                    name: '宜黄县',
                },
                '1362': {
                    name: '金溪县',
                },
                '1363': {
                    name: '资溪县',
                },
                '1364': {
                    name: '东乡县',
                },
                '1365': {
                    name: '广昌县',
                },
            },
        },
        '1366': {
            name: '上饶',
            area: {
                '1367': {
                    name: '信州区',
                },
                '1368': {
                    name: '上饶县',
                },
                '1369': {
                    name: '广丰县',
                },
                '1370': {
                    name: '玉山县',
                },
                '1371': {
                    name: '铅山县',
                },
                '1372': {
                    name: '横峰县',
                },
                '1373': {
                    name: '弋阳县',
                },
                '1374': {
                    name: '余干县',
                },
                '1375': {
                    name: '鄱阳县',
                },
                '1376': {
                    name: '万年县',
                },
                '1377': {
                    name: '婺源县',
                },
                '1378': {
                    name: '德兴',
                },
            },
        },
    },
    '1379': {
        '1380': {
            name: '济南',
            area: {
                '1381': {
                    name: '历下区',
                },
                '1382': {
                    name: '市中区',
                },
                '1383': {
                    name: '槐荫区',
                },
                '1384': {
                    name: '天桥区',
                },
                '1385': {
                    name: '历城区',
                },
                '1386': {
                    name: '长清区',
                },
                '1387': {
                    name: '平阴县',
                },
                '1388': {
                    name: '济阳县',
                },
                '1389': {
                    name: '商河县',
                },
                '1390': {
                    name: '章丘',
                },
            },
        },
        '1391': {
            name: '青岛',
            area: {
                '1392': {
                    name: '市南区',
                },
                '1393': {
                    name: '市北区',
                },
                '1394': {
                    name: '四方区',
                },
                '1395': {
                    name: '黄岛区',
                },
                '1396': {
                    name: '崂山区',
                },
                '1397': {
                    name: '李沧区',
                },
                '1398': {
                    name: '城阳区',
                },
                '1399': {
                    name: '胶州',
                },
                '1400': {
                    name: '即墨',
                },
                '1401': {
                    name: '平度',
                },
                '1402': {
                    name: '胶南',
                },
                '1403': {
                    name: '莱西',
                },
            },
        },
        '1404': {
            name: '淄博',
            area: {
                '1405': {
                    name: '淄川区',
                },
                '1406': {
                    name: '张店区',
                },
                '1407': {
                    name: '博山区',
                },
                '1408': {
                    name: '临淄区',
                },
                '1409': {
                    name: '周村区',
                },
                '1410': {
                    name: '桓台县',
                },
                '1411': {
                    name: '高青县',
                },
                '1412': {
                    name: '沂源县',
                },
            },
        },
        '1413': {
            name: '枣庄',
            area: {
                '1414': {
                    name: '市中区',
                },
                '1415': {
                    name: '薛城区',
                },
                '1416': {
                    name: '峄城区',
                },
                '1417': {
                    name: '台儿庄区',
                },
                '1418': {
                    name: '山亭区',
                },
                '1419': {
                    name: '滕州',
                },
            },
        },
        '1420': {
            name: '东营',
            area: {
                '1421': {
                    name: '东营区',
                },
                '1422': {
                    name: '河口区',
                },
                '1423': {
                    name: '垦利县',
                },
                '1424': {
                    name: '利津县',
                },
                '1425': {
                    name: '广饶县',
                },
            },
        },
        '1426': {
            name: '烟台',
            area: {
                '1427': {
                    name: '芝罘区',
                },
                '1428': {
                    name: '福山区',
                },
                '1429': {
                    name: '牟平区',
                },
                '1430': {
                    name: '莱山区',
                },
                '1431': {
                    name: '长岛县',
                },
                '1432': {
                    name: '龙口',
                },
                '1433': {
                    name: '莱阳',
                },
                '1434': {
                    name: '莱州',
                },
                '1435': {
                    name: '蓬莱',
                },
                '1436': {
                    name: '招远',
                },
                '1437': {
                    name: '栖霞',
                },
                '1438': {
                    name: '海阳',
                },
            },
        },
        '1439': {
            name: '潍坊',
            area: {
                '1440': {
                    name: '潍城区',
                },
                '1441': {
                    name: '寒亭区',
                },
                '1442': {
                    name: '坊子区',
                },
                '1443': {
                    name: '奎文区',
                },
                '1444': {
                    name: '临朐县',
                },
                '1445': {
                    name: '昌乐县',
                },
                '1446': {
                    name: '青州',
                },
                '1447': {
                    name: '诸城',
                },
                '1448': {
                    name: '寿光',
                },
                '1449': {
                    name: '安丘',
                },
                '1450': {
                    name: '高密',
                },
                '1451': {
                    name: '昌邑',
                },
            },
        },
        '1452': {
            name: '济宁',
            area: {
                '1453': {
                    name: '市中区',
                },
                '1454': {
                    name: '任城区',
                },
                '1455': {
                    name: '微山县',
                },
                '1456': {
                    name: '鱼台县',
                },
                '1457': {
                    name: '金乡县',
                },
                '1458': {
                    name: '嘉祥县',
                },
                '1459': {
                    name: '汶上县',
                },
                '1460': {
                    name: '泗水县',
                },
                '1461': {
                    name: '梁山县',
                },
                '1462': {
                    name: '曲阜',
                },
                '1463': {
                    name: '兖州',
                },
                '1464': {
                    name: '邹城',
                },
            },
        },
        '1465': {
            name: '泰安',
            area: {
                '1466': {
                    name: '泰山区',
                },
                '1467': {
                    name: '岱岳区',
                },
                '1468': {
                    name: '宁阳县',
                },
                '1469': {
                    name: '东平县',
                },
                '1470': {
                    name: '新泰',
                },
                '1471': {
                    name: '肥城',
                },
            },
        },
        '1472': {
            name: '威海',
            area: {
                '1473': {
                    name: '环翠区',
                },
                '1474': {
                    name: '文登',
                },
                '1475': {
                    name: '荣成',
                },
                '1476': {
                    name: '乳山',
                },
            },
        },
        '1477': {
            name: '日照',
            area: {
                '1478': {
                    name: '东港区',
                },
                '1479': {
                    name: '岚山区',
                },
                '1480': {
                    name: '五莲县',
                },
                '1481': {
                    name: '莒县',
                },
            },
        },
        '1482': {
            name: '莱芜',
            area: {
                '1483': {
                    name: '莱城区',
                },
                '1484': {
                    name: '钢城区',
                },
            },
        },
        '1485': {
            name: '临沂',
            area: {
                '1486': {
                    name: '兰山区',
                },
                '1487': {
                    name: '罗庄区',
                },
                '1488': {
                    name: '河东区',
                },
                '1489': {
                    name: '沂南县',
                },
                '1490': {
                    name: '郯城县',
                },
                '1491': {
                    name: '沂水县',
                },
                '1492': {
                    name: '苍山县',
                },
                '1493': {
                    name: '费县',
                },
                '1494': {
                    name: '平邑县',
                },
                '1495': {
                    name: '莒南县',
                },
                '1496': {
                    name: '蒙阴县',
                },
                '1497': {
                    name: '临沭县',
                },
            },
        },
        '1498': {
            name: '德州',
            area: {
                '1499': {
                    name: '德城区',
                },
                '1500': {
                    name: '陵县',
                },
                '1501': {
                    name: '宁津县',
                },
                '1502': {
                    name: '庆云县',
                },
                '1503': {
                    name: '临邑县',
                },
                '1504': {
                    name: '齐河县',
                },
                '1505': {
                    name: '平原县',
                },
                '1506': {
                    name: '夏津县',
                },
                '1507': {
                    name: '武城县',
                },
                '1508': {
                    name: '乐陵',
                },
                '1509': {
                    name: '禹城',
                },
            },
        },
        '1510': {
            name: '聊城',
            area: {
                '1511': {
                    name: '东昌府区',
                },
                '1512': {
                    name: '阳谷县',
                },
                '1513': {
                    name: '莘县',
                },
                '1514': {
                    name: '茌平县',
                },
                '1515': {
                    name: '东阿县',
                },
                '1516': {
                    name: '冠县',
                },
                '1517': {
                    name: '高唐县',
                },
                '1518': {
                    name: '临清',
                },
            },
        },
        '1519': {
            name: '滨州',
            area: {
                '1520': {
                    name: '滨城区',
                },
                '1521': {
                    name: '惠民县',
                },
                '1522': {
                    name: '阳信县',
                },
                '1523': {
                    name: '无棣县',
                },
                '1524': {
                    name: '沾化县',
                },
                '1525': {
                    name: '博兴县',
                },
                '1526': {
                    name: '邹平县',
                },
            },
        },
        '1527': {
            name: '菏泽',
            area: {
                '1528': {
                    name: '牡丹区',
                },
                '1529': {
                    name: '曹县',
                },
                '1530': {
                    name: '单县',
                },
                '1531': {
                    name: '成武县',
                },
                '1532': {
                    name: '巨野县',
                },
                '1533': {
                    name: '郓城县',
                },
                '1534': {
                    name: '鄄城县',
                },
                '1535': {
                    name: '定陶县',
                },
                '1536': {
                    name: '东明县',
                },
            },
        },
    },
    '1537': {
        '1538': {
            name: '郑州',
            area: {
                '1539': {
                    name: '中原区',
                },
                '1540': {
                    name: '二七区',
                },
                '1541': {
                    name: '管城区',
                },
                '1542': {
                    name: '金水区',
                },
                '1543': {
                    name: '上街区',
                },
                '1544': {
                    name: '惠济区',
                },
                '1545': {
                    name: '中牟县',
                },
                '1546': {
                    name: '巩义',
                },
                '1547': {
                    name: '荥阳',
                },
                '1548': {
                    name: '新密',
                },
                '1549': {
                    name: '新郑',
                },
                '1550': {
                    name: '登封',
                },
            },
        },
        '1551': {
            name: '开封',
            area: {
                '1552': {
                    name: '龙亭区',
                },
                '1553': {
                    name: '顺河区',
                },
                '1554': {
                    name: '鼓楼区',
                },
                '1555': {
                    name: '禹王台区',
                },
                '1556': {
                    name: '金明区',
                },
                '1557': {
                    name: '杞县',
                },
                '1558': {
                    name: '通许县',
                },
                '1559': {
                    name: '尉氏县',
                },
                '1560': {
                    name: '开封县',
                },
                '1561': {
                    name: '兰考县',
                },
            },
        },
        '1562': {
            name: '洛阳',
            area: {
                '1563': {
                    name: '老城区',
                },
                '1564': {
                    name: '西工区',
                },
                '1565': {
                    name: '瀍河区',
                },
                '1566': {
                    name: '涧西区',
                },
                '1567': {
                    name: '吉利区',
                },
                '1568': {
                    name: '洛龙区',
                },
                '1569': {
                    name: '孟津县',
                },
                '1570': {
                    name: '新安县',
                },
                '1571': {
                    name: '栾川县',
                },
                '1572': {
                    name: '嵩县',
                },
                '1573': {
                    name: '汝阳县',
                },
                '1574': {
                    name: '宜阳县',
                },
                '1575': {
                    name: '洛宁县',
                },
                '1576': {
                    name: '伊川县',
                },
                '1577': {
                    name: '偃师',
                },
            },
        },
        '1578': {
            name: '平顶山',
            area: {
                '1579': {
                    name: '新华区',
                },
                '1580': {
                    name: '卫东区',
                },
                '1581': {
                    name: '石龙区',
                },
                '1582': {
                    name: '湛河区',
                },
                '1583': {
                    name: '宝丰县',
                },
                '1584': {
                    name: '叶县',
                },
                '1585': {
                    name: '鲁山县',
                },
                '1586': {
                    name: '郏县',
                },
                '1587': {
                    name: '舞钢',
                },
                '1588': {
                    name: '汝州',
                },
            },
        },
        '1589': {
            name: '安阳',
            area: {
                '1590': {
                    name: '文峰区',
                },
                '1591': {
                    name: '北关区',
                },
                '1592': {
                    name: '殷都区',
                },
                '1593': {
                    name: '龙安区',
                },
                '1594': {
                    name: '安阳县',
                },
                '1595': {
                    name: '汤阴县',
                },
                '1596': {
                    name: '滑县',
                },
                '1597': {
                    name: '内黄县',
                },
                '1598': {
                    name: '林州',
                },
            },
        },
        '1599': {
            name: '鹤壁',
            area: {
                '1600': {
                    name: '鹤山区',
                },
                '1601': {
                    name: '山城区',
                },
                '1602': {
                    name: '淇滨区',
                },
                '1603': {
                    name: '浚县',
                },
                '1604': {
                    name: '淇县',
                },
            },
        },
        '1605': {
            name: '新乡',
            area: {
                '1606': {
                    name: '红旗区',
                },
                '1607': {
                    name: '卫滨区',
                },
                '1608': {
                    name: '凤泉区',
                },
                '1609': {
                    name: '牧野区',
                },
                '1610': {
                    name: '新乡县',
                },
                '1611': {
                    name: '获嘉县',
                },
                '1612': {
                    name: '原阳县',
                },
                '1613': {
                    name: '延津县',
                },
                '1614': {
                    name: '封丘县',
                },
                '1615': {
                    name: '长垣县',
                },
                '1616': {
                    name: '卫辉',
                },
                '1617': {
                    name: '辉县',
                },
            },
        },
        '1618': {
            name: '焦作',
            area: {
                '1619': {
                    name: '解放区',
                },
                '1620': {
                    name: '中站区',
                },
                '1621': {
                    name: '马村区',
                },
                '1622': {
                    name: '山阳区',
                },
                '1623': {
                    name: '修武县',
                },
                '1624': {
                    name: '博爱县',
                },
                '1625': {
                    name: '武陟县',
                },
                '1626': {
                    name: '温县',
                },
                '1627': {
                    name: '沁阳',
                },
                '1628': {
                    name: '孟州',
                },
            },
        },
        '1629': {
            name: '濮阳',
            area: {
                '1630': {
                    name: '华龙区',
                },
                '1631': {
                    name: '清丰县',
                },
                '1632': {
                    name: '南乐县',
                },
                '1633': {
                    name: '范县',
                },
                '1634': {
                    name: '台前县',
                },
                '1635': {
                    name: '濮阳县',
                },
            },
        },
        '1636': {
            name: '许昌',
            area: {
                '1637': {
                    name: '魏都区',
                },
                '1638': {
                    name: '许昌县',
                },
                '1639': {
                    name: '鄢陵县',
                },
                '1640': {
                    name: '襄城县',
                },
                '1641': {
                    name: '禹州',
                },
                '1642': {
                    name: '长葛',
                },
            },
        },
        '1643': {
            name: '漯河',
            area: {
                '1644': {
                    name: '源汇区',
                },
                '1645': {
                    name: '郾城区',
                },
                '1646': {
                    name: '召陵区',
                },
                '1647': {
                    name: '舞阳县',
                },
                '1648': {
                    name: '临颍县',
                },
            },
        },
        '1649': {
            name: '三门峡',
            area: {
                '1650': {
                    name: '湖滨区',
                },
                '1651': {
                    name: '渑池县',
                },
                '1652': {
                    name: '陕县',
                },
                '1653': {
                    name: '卢氏县',
                },
                '1654': {
                    name: '义马',
                },
                '1655': {
                    name: '灵宝',
                },
            },
        },
        '1656': {
            name: '南阳',
            area: {
                '1657': {
                    name: '宛城区',
                },
                '1658': {
                    name: '卧龙区',
                },
                '1659': {
                    name: '南召县',
                },
                '1660': {
                    name: '方城县',
                },
                '1661': {
                    name: '西峡县',
                },
                '1662': {
                    name: '镇平县',
                },
                '1663': {
                    name: '内乡县',
                },
                '1664': {
                    name: '淅川县',
                },
                '1665': {
                    name: '社旗县',
                },
                '1666': {
                    name: '唐河县',
                },
                '1667': {
                    name: '新野县',
                },
                '1668': {
                    name: '桐柏县',
                },
                '1669': {
                    name: '邓州',
                },
            },
        },
        '1670': {
            name: '商丘',
            area: {
                '1671': {
                    name: '梁园区',
                },
                '1672': {
                    name: '睢阳区',
                },
                '1673': {
                    name: '民权县',
                },
                '1674': {
                    name: '睢县',
                },
                '1675': {
                    name: '宁陵县',
                },
                '1676': {
                    name: '柘城县',
                },
                '1677': {
                    name: '虞城县',
                },
                '1678': {
                    name: '夏邑县',
                },
                '1679': {
                    name: '永城',
                },
            },
        },
        '1680': {
            name: '信阳',
            area: {
                '1681': {
                    name: '浉河区',
                },
                '1682': {
                    name: '平桥区',
                },
                '1683': {
                    name: '罗山县',
                },
                '1684': {
                    name: '光山县',
                },
                '1685': {
                    name: '新县',
                },
                '1686': {
                    name: '商城县',
                },
                '1687': {
                    name: '固始县',
                },
                '1688': {
                    name: '潢川县',
                },
                '1689': {
                    name: '淮滨县',
                },
                '1690': {
                    name: '息县',
                },
            },
        },
        '1691': {
            name: '周口',
            area: {
                '1692': {
                    name: '川汇区',
                },
                '1693': {
                    name: '扶沟县',
                },
                '1694': {
                    name: '西华县',
                },
                '1695': {
                    name: '商水县',
                },
                '1696': {
                    name: '沈丘县',
                },
                '1697': {
                    name: '郸城县',
                },
                '1698': {
                    name: '淮阳县',
                },
                '1699': {
                    name: '太康县',
                },
                '1700': {
                    name: '鹿邑县',
                },
                '1701': {
                    name: '项城',
                },
            },
        },
        '1702': {
            name: '驻马店',
            area: {
                '1703': {
                    name: '驿城区',
                },
                '1704': {
                    name: '西平县',
                },
                '1705': {
                    name: '上蔡县',
                },
                '1706': {
                    name: '平舆县',
                },
                '1707': {
                    name: '正阳县',
                },
                '1708': {
                    name: '确山县',
                },
                '1709': {
                    name: '泌阳县',
                },
                '1710': {
                    name: '汝南县',
                },
                '1711': {
                    name: '遂平县',
                },
                '1712': {
                    name: '新蔡县',
                },
            },
        },
        '1713': {
            name: '直辖市',
            area: {
                '1714': {
                    name: '济源',
                },
            },
        },
    },
    '1715': {
        '1716': {
            name: '武汉',
            area: {
                '1717': {
                    name: '江岸区',
                },
                '1718': {
                    name: '江汉区',
                },
                '1719': {
                    name: '硚口区',
                },
                '1720': {
                    name: '汉阳区',
                },
                '1721': {
                    name: '武昌区',
                },
                '1722': {
                    name: '青山区',
                },
                '1723': {
                    name: '洪山区',
                },
                '1724': {
                    name: '东西湖区',
                },
                '1725': {
                    name: '汉南区',
                },
                '1726': {
                    name: '蔡甸区',
                },
                '1727': {
                    name: '江夏区',
                },
                '1728': {
                    name: '黄陂区',
                },
                '1729': {
                    name: '新洲区',
                },
            },
        },
        '1730': {
            name: '黄石',
            area: {
                '1731': {
                    name: '黄石港区',
                },
                '1732': {
                    name: '西塞山区',
                },
                '1733': {
                    name: '下陆区',
                },
                '1734': {
                    name: '铁山区',
                },
                '1735': {
                    name: '阳新县',
                },
                '1736': {
                    name: '大冶',
                },
            },
        },
        '1737': {
            name: '十堰',
            area: {
                '1738': {
                    name: '茅箭区',
                },
                '1739': {
                    name: '张湾区',
                },
                '1740': {
                    name: '郧县',
                },
                '1741': {
                    name: '郧西县',
                },
                '1742': {
                    name: '竹山县',
                },
                '1743': {
                    name: '竹溪县',
                },
                '1744': {
                    name: '房县',
                },
                '1745': {
                    name: '丹江口',
                },
            },
        },
        '1746': {
            name: '宜昌',
            area: {
                '1747': {
                    name: '西陵区',
                },
                '1748': {
                    name: '伍家岗区',
                },
                '1749': {
                    name: '点军区',
                },
                '1750': {
                    name: '猇亭区',
                },
                '1751': {
                    name: '夷陵区',
                },
                '1752': {
                    name: '远安县',
                },
                '1753': {
                    name: '兴山县',
                },
                '1754': {
                    name: '秭归县',
                },
                '1755': {
                    name: '长阳',
                },
                '1756': {
                    name: '五峰',
                },
                '1757': {
                    name: '宜都',
                },
                '1758': {
                    name: '当阳',
                },
                '1759': {
                    name: '枝江',
                },
            },
        },
        '1760': {
            name: '襄阳',
            area: {
                '1761': {
                    name: '襄城区',
                },
                '1762': {
                    name: '樊城区',
                },
                '1763': {
                    name: '襄州区',
                },
                '1764': {
                    name: '南漳县',
                },
                '1765': {
                    name: '谷城县',
                },
                '1766': {
                    name: '保康县',
                },
                '1767': {
                    name: '老河口',
                },
                '1768': {
                    name: '枣阳',
                },
                '1769': {
                    name: '宜城',
                },
            },
        },
        '1770': {
            name: '鄂州',
            area: {
                '1771': {
                    name: '梁子湖区',
                },
                '1772': {
                    name: '华容区',
                },
                '1773': {
                    name: '鄂城区',
                },
            },
        },
        '1774': {
            name: '荆门',
            area: {
                '1775': {
                    name: '东宝区',
                },
                '1776': {
                    name: '掇刀区',
                },
                '1777': {
                    name: '京山县',
                },
                '1778': {
                    name: '沙洋县',
                },
                '1779': {
                    name: '钟祥',
                },
            },
        },
        '1780': {
            name: '孝感',
            area: {
                '1781': {
                    name: '孝南区',
                },
                '1782': {
                    name: '孝昌县',
                },
                '1783': {
                    name: '大悟县',
                },
                '1784': {
                    name: '云梦县',
                },
                '1785': {
                    name: '应城',
                },
                '1786': {
                    name: '安陆',
                },
                '1787': {
                    name: '汉川',
                },
            },
        },
        '1788': {
            name: '荆州',
            area: {
                '1789': {
                    name: '沙市区',
                },
                '1790': {
                    name: '荆州区',
                },
                '1791': {
                    name: '公安县',
                },
                '1792': {
                    name: '监利县',
                },
                '1793': {
                    name: '江陵县',
                },
                '1794': {
                    name: '石首',
                },
                '1795': {
                    name: '洪湖',
                },
                '1796': {
                    name: '松滋',
                },
            },
        },
        '1797': {
            name: '黄冈',
            area: {
                '1798': {
                    name: '黄州区',
                },
                '1799': {
                    name: '团风县',
                },
                '1800': {
                    name: '红安县',
                },
                '1801': {
                    name: '罗田县',
                },
                '1802': {
                    name: '英山县',
                },
                '1803': {
                    name: '浠水县',
                },
                '1804': {
                    name: '蕲春县',
                },
                '1805': {
                    name: '黄梅县',
                },
                '1806': {
                    name: '麻城',
                },
                '1807': {
                    name: '武穴',
                },
            },
        },
        '1808': {
            name: '咸宁',
            area: {
                '1809': {
                    name: '咸安区',
                },
                '1810': {
                    name: '嘉鱼县',
                },
                '1811': {
                    name: '通城县',
                },
                '1812': {
                    name: '崇阳县',
                },
                '1813': {
                    name: '通山县',
                },
                '1814': {
                    name: '赤壁',
                },
            },
        },
        '1815': {
            name: '随州',
            area: {
                '1816': {
                    name: '曾都区',
                },
                '1817': {
                    name: '随县',
                },
                '1818': {
                    name: '广水',
                },
            },
        },
        '1819': {
            name: '恩施',
            area: {
                '1820': {
                    name: '恩施',
                },
                '1821': {
                    name: '利川',
                },
                '1822': {
                    name: '建始县',
                },
                '1823': {
                    name: '巴东县',
                },
                '1824': {
                    name: '宣恩县',
                },
                '1825': {
                    name: '咸丰县',
                },
                '1826': {
                    name: '来凤县',
                },
                '1827': {
                    name: '鹤峰县',
                },
            },
        },
        '1828': {
            name: '直辖市',
            area: {
                '1829': {
                    name: '仙桃',
                },
                '1830': {
                    name: '潜江',
                },
                '1831': {
                    name: '天门',
                },
                '1832': {
                    name: '神农架林区',
                },
            },
        },
    },
    '1833': {
        '1834': {
            name: '长沙',
            area: {
                '1835': {
                    name: '芙蓉区',
                },
                '1836': {
                    name: '天心区',
                },
                '1837': {
                    name: '岳麓区',
                },
                '1838': {
                    name: '开福区',
                },
                '1839': {
                    name: '雨花区',
                },
                '1840': {
                    name: '望城区',
                },
                '1841': {
                    name: '长沙县',
                },
                '1842': {
                    name: '宁乡县',
                },
                '1843': {
                    name: '浏阳',
                },
            },
        },
        '1844': {
            name: '株洲',
            area: {
                '1845': {
                    name: '荷塘区',
                },
                '1846': {
                    name: '芦淞区',
                },
                '1847': {
                    name: '石峰区',
                },
                '1848': {
                    name: '天元区',
                },
                '1849': {
                    name: '株洲县',
                },
                '1850': {
                    name: '攸县',
                },
                '1851': {
                    name: '茶陵县',
                },
                '1852': {
                    name: '炎陵县',
                },
                '1853': {
                    name: '醴陵',
                },
            },
        },
        '1854': {
            name: '湘潭',
            area: {
                '1855': {
                    name: '雨湖区',
                },
                '1856': {
                    name: '岳塘区',
                },
                '1857': {
                    name: '湘潭县',
                },
                '1858': {
                    name: '湘乡',
                },
                '1859': {
                    name: '韶山',
                },
            },
        },
        '1860': {
            name: '衡阳',
            area: {
                '1861': {
                    name: '珠晖区',
                },
                '1862': {
                    name: '雁峰区',
                },
                '1863': {
                    name: '石鼓区',
                },
                '1864': {
                    name: '蒸湘区',
                },
                '1865': {
                    name: '南岳区',
                },
                '1866': {
                    name: '衡阳县',
                },
                '1867': {
                    name: '衡南县',
                },
                '1868': {
                    name: '衡山县',
                },
                '1869': {
                    name: '衡东县',
                },
                '1870': {
                    name: '祁东县',
                },
                '1871': {
                    name: '耒阳',
                },
                '1872': {
                    name: '常宁',
                },
            },
        },
        '1873': {
            name: '邵阳',
            area: {
                '1874': {
                    name: '双清区',
                },
                '1875': {
                    name: '大祥区',
                },
                '1876': {
                    name: '北塔区',
                },
                '1877': {
                    name: '邵东县',
                },
                '1878': {
                    name: '新邵县',
                },
                '1879': {
                    name: '邵阳县',
                },
                '1880': {
                    name: '隆回县',
                },
                '1881': {
                    name: '洞口县',
                },
                '1882': {
                    name: '绥宁县',
                },
                '1883': {
                    name: '新宁县',
                },
                '1884': {
                    name: '城步',
                },
                '1885': {
                    name: '武冈',
                },
            },
        },
        '1886': {
            name: '岳阳',
            area: {
                '1887': {
                    name: '岳阳楼区',
                },
                '1888': {
                    name: '云溪区',
                },
                '1889': {
                    name: '君山区',
                },
                '1890': {
                    name: '岳阳县',
                },
                '1891': {
                    name: '华容县',
                },
                '1892': {
                    name: '湘阴县',
                },
                '1893': {
                    name: '平江县',
                },
                '1894': {
                    name: '汨罗',
                },
                '1895': {
                    name: '临湘',
                },
            },
        },
        '1896': {
            name: '常德',
            area: {
                '1897': {
                    name: '武陵区',
                },
                '1898': {
                    name: '鼎城区',
                },
                '1899': {
                    name: '安乡县',
                },
                '1900': {
                    name: '汉寿县',
                },
                '1901': {
                    name: '澧县',
                },
                '1902': {
                    name: '临澧县',
                },
                '1903': {
                    name: '桃源县',
                },
                '1904': {
                    name: '石门县',
                },
                '1905': {
                    name: '津市',
                },
            },
        },
        '1906': {
            name: '张家界',
            area: {
                '1907': {
                    name: '永定区',
                },
                '1908': {
                    name: '武陵源区',
                },
                '1909': {
                    name: '慈利县',
                },
                '1910': {
                    name: '桑植县',
                },
            },
        },
        '1911': {
            name: '益阳',
            area: {
                '1912': {
                    name: '资阳区',
                },
                '1913': {
                    name: '赫山区',
                },
                '1914': {
                    name: '南县',
                },
                '1915': {
                    name: '桃江县',
                },
                '1916': {
                    name: '安化县',
                },
                '1917': {
                    name: '沅江',
                },
            },
        },
        '1918': {
            name: '郴州',
            area: {
                '1919': {
                    name: '北湖区',
                },
                '1920': {
                    name: '苏仙区',
                },
                '1921': {
                    name: '桂阳县',
                },
                '1922': {
                    name: '宜章县',
                },
                '1923': {
                    name: '永兴县',
                },
                '1924': {
                    name: '嘉禾县',
                },
                '1925': {
                    name: '临武县',
                },
                '1926': {
                    name: '汝城县',
                },
                '1927': {
                    name: '桂东县',
                },
                '1928': {
                    name: '安仁县',
                },
                '1929': {
                    name: '资兴',
                },
            },
        },
        '1930': {
            name: '永州',
            area: {
                '1931': {
                    name: '零陵区',
                },
                '1932': {
                    name: '冷水滩区',
                },
                '1933': {
                    name: '祁阳县',
                },
                '1934': {
                    name: '东安县',
                },
                '1935': {
                    name: '双牌县',
                },
                '1936': {
                    name: '道县',
                },
                '1937': {
                    name: '江永县',
                },
                '1938': {
                    name: '宁远县',
                },
                '1939': {
                    name: '蓝山县',
                },
                '1940': {
                    name: '新田县',
                },
                '1941': {
                    name: '江华',
                },
            },
        },
        '1942': {
            name: '怀化',
            area: {
                '1943': {
                    name: '鹤城区',
                },
                '1944': {
                    name: '中方县',
                },
                '1945': {
                    name: '沅陵县',
                },
                '1946': {
                    name: '辰溪县',
                },
                '1947': {
                    name: '溆浦县',
                },
                '1948': {
                    name: '会同县',
                },
                '1949': {
                    name: '麻阳',
                },
                '1950': {
                    name: '新晃',
                },
                '1951': {
                    name: '芷江',
                },
                '1952': {
                    name: '靖州',
                },
                '1953': {
                    name: '通道县',
                },
                '1954': {
                    name: '洪江',
                },
            },
        },
        '1955': {
            name: '娄底',
            area: {
                '1956': {
                    name: '娄星区',
                },
                '1957': {
                    name: '双峰县',
                },
                '1958': {
                    name: '新化县',
                },
                '1959': {
                    name: '冷水江',
                },
                '1960': {
                    name: '涟源',
                },
            },
        },
        '1961': {
            name: '湘西',
            area: {
                '1962': {
                    name: '吉首',
                },
                '1963': {
                    name: '泸溪县',
                },
                '1964': {
                    name: '凤凰县',
                },
                '1965': {
                    name: '花垣县',
                },
                '1966': {
                    name: '保靖县',
                },
                '1967': {
                    name: '古丈县',
                },
                '1968': {
                    name: '永顺县',
                },
                '1969': {
                    name: '龙山县',
                },
            },
        },
    },
    '1970': {
        '1971': {
            name: '广州',
            area: {
                '1972': {
                    name: '荔湾区',
                },
                '1973': {
                    name: '越秀区',
                },
                '1974': {
                    name: '海珠区',
                },
                '1975': {
                    name: '天河区',
                },
                '1976': {
                    name: '白云区',
                },
                '1977': {
                    name: '黄埔区',
                },
                '1978': {
                    name: '番禺区',
                },
                '1979': {
                    name: '花都区',
                },
                '1980': {
                    name: '南沙区',
                },
                '1981': {
                    name: '萝岗区',
                },
                '1982': {
                    name: '增城',
                },
                '1983': {
                    name: '从化',
                },
            },
        },
        '1984': {
            name: '韶关',
            area: {
                '1985': {
                    name: '武江区',
                },
                '1986': {
                    name: '浈江区',
                },
                '1987': {
                    name: '曲江区',
                },
                '1988': {
                    name: '始兴县',
                },
                '1989': {
                    name: '仁化县',
                },
                '1990': {
                    name: '翁源县',
                },
                '1991': {
                    name: '乳源县',
                },
                '1992': {
                    name: '新丰县',
                },
                '1993': {
                    name: '乐昌',
                },
                '1994': {
                    name: '南雄',
                },
            },
        },
        '1995': {
            name: '深圳',
            area: {
                '1996': {
                    name: '罗湖区',
                },
                '1997': {
                    name: '福田区',
                },
                '1998': {
                    name: '南山区',
                },
                '1999': {
                    name: '宝安区',
                },
                '2000': {
                    name: '龙岗区',
                },
                '2001': {
                    name: '盐田区',
                },
            },
        },
        '2002': {
            name: '珠海',
            area: {
                '2003': {
                    name: '香洲区',
                },
                '2004': {
                    name: '斗门区',
                },
                '2005': {
                    name: '金湾区',
                },
            },
        },
        '2006': {
            name: '汕头',
            area: {
                '2007': {
                    name: '龙湖区',
                },
                '2008': {
                    name: '金平区',
                },
                '2009': {
                    name: '濠江区',
                },
                '2010': {
                    name: '潮阳区',
                },
                '2011': {
                    name: '潮南区',
                },
                '2012': {
                    name: '澄海区',
                },
                '2013': {
                    name: '南澳县',
                },
            },
        },
        '2014': {
            name: '佛山',
            area: {
                '2015': {
                    name: '禅城区',
                },
                '2016': {
                    name: '南海区',
                },
                '2017': {
                    name: '顺德区',
                },
                '2018': {
                    name: '三水区',
                },
                '2019': {
                    name: '高明区',
                },
            },
        },
        '2020': {
            name: '江门',
            area: {
                '2021': {
                    name: '蓬江区',
                },
                '2022': {
                    name: '江海区',
                },
                '2023': {
                    name: '新会区',
                },
                '2024': {
                    name: '台山',
                },
                '2025': {
                    name: '开平',
                },
                '2026': {
                    name: '鹤山',
                },
                '2027': {
                    name: '恩平',
                },
            },
        },
        '2028': {
            name: '湛江',
            area: {
                '2029': {
                    name: '赤坎区',
                },
                '2030': {
                    name: '霞山区',
                },
                '2031': {
                    name: '坡头区',
                },
                '2032': {
                    name: '麻章区',
                },
                '2033': {
                    name: '遂溪县',
                },
                '2034': {
                    name: '徐闻县',
                },
                '2035': {
                    name: '廉江',
                },
                '2036': {
                    name: '雷州',
                },
                '2037': {
                    name: '吴川',
                },
            },
        },
        '2038': {
            name: '茂名',
            area: {
                '2039': {
                    name: '茂南区',
                },
                '2040': {
                    name: '茂港区',
                },
                '2041': {
                    name: '电白县',
                },
                '2042': {
                    name: '高州',
                },
                '2043': {
                    name: '化州',
                },
                '2044': {
                    name: '信宜',
                },
            },
        },
        '2045': {
            name: '肇庆',
            area: {
                '2046': {
                    name: '端州区',
                },
                '2047': {
                    name: '鼎湖区',
                },
                '2048': {
                    name: '广宁县',
                },
                '2049': {
                    name: '怀集县',
                },
                '2050': {
                    name: '封开县',
                },
                '2051': {
                    name: '德庆县',
                },
                '2052': {
                    name: '高要',
                },
                '2053': {
                    name: '四会',
                },
            },
        },
        '2054': {
            name: '惠州',
            area: {
                '2055': {
                    name: '惠城区',
                },
                '2056': {
                    name: '惠阳区',
                },
                '2057': {
                    name: '博罗县',
                },
                '2058': {
                    name: '惠东县',
                },
                '2059': {
                    name: '龙门县',
                },
            },
        },
        '2060': {
            name: '梅州',
            area: {
                '2061': {
                    name: '梅江区',
                },
                '2062': {
                    name: '梅县',
                },
                '2063': {
                    name: '大埔县',
                },
                '2064': {
                    name: '丰顺县',
                },
                '2065': {
                    name: '五华县',
                },
                '2066': {
                    name: '平远县',
                },
                '2067': {
                    name: '蕉岭县',
                },
                '2068': {
                    name: '兴宁',
                },
            },
        },
        '2069': {
            name: '汕尾',
            area: {
                '2070': {
                    name: '城区',
                },
                '2071': {
                    name: '海丰县',
                },
                '2072': {
                    name: '陆河县',
                },
                '2073': {
                    name: '陆丰',
                },
            },
        },
        '2074': {
            name: '河源',
            area: {
                '2075': {
                    name: '源城区',
                },
                '2076': {
                    name: '紫金县',
                },
                '2077': {
                    name: '龙川县',
                },
                '2078': {
                    name: '连平县',
                },
                '2079': {
                    name: '和平县',
                },
                '2080': {
                    name: '东源县',
                },
            },
        },
        '2081': {
            name: '阳江',
            area: {
                '2082': {
                    name: '江城区',
                },
                '2083': {
                    name: '阳西县',
                },
                '2084': {
                    name: '阳东县',
                },
                '2085': {
                    name: '阳春',
                },
            },
        },
        '2086': {
            name: '清远',
            area: {
                '2087': {
                    name: '清城区',
                },
                '2088': {
                    name: '佛冈县',
                },
                '2089': {
                    name: '阳山县',
                },
                '2090': {
                    name: '连山县',
                },
                '2091': {
                    name: '连南县',
                },
                '2092': {
                    name: '清新县',
                },
                '2093': {
                    name: '英德',
                },
                '2094': {
                    name: '连州',
                },
            },
        },
        '2095': {
            name: '东莞',
        },
        '2096': {
            name: '中山',
        },
        '2097': {
            name: '潮州',
            area: {
                '2098': {
                    name: '湘桥区',
                },
                '2099': {
                    name: '潮安县',
                },
                '2100': {
                    name: '饶平县',
                },
            },
        },
        '2101': {
            name: '揭阳',
            area: {
                '2102': {
                    name: '榕城区',
                },
                '2103': {
                    name: '揭东县',
                },
                '2104': {
                    name: '揭西县',
                },
                '2105': {
                    name: '惠来县',
                },
                '2106': {
                    name: '普宁',
                },
            },
        },
        '2107': {
            name: '云浮',
            area: {
                '2108': {
                    name: '云城区',
                },
                '2109': {
                    name: '新兴县',
                },
                '2110': {
                    name: '郁南县',
                },
                '2111': {
                    name: '云安县',
                },
                '2112': {
                    name: '罗定',
                },
            },
        },
    },
    '2113': {
        '2114': {
            name: '南宁',
            area: {
                '2115': {
                    name: '兴宁区',
                },
                '2116': {
                    name: '青秀区',
                },
                '2117': {
                    name: '江南区',
                },
                '2118': {
                    name: '西乡塘区',
                },
                '2119': {
                    name: '良庆区',
                },
                '2120': {
                    name: '邕宁区',
                },
                '2121': {
                    name: '武鸣县',
                },
                '2122': {
                    name: '隆安县',
                },
                '2123': {
                    name: '马山县',
                },
                '2124': {
                    name: '上林县',
                },
                '2125': {
                    name: '宾阳县',
                },
                '2126': {
                    name: '横县',
                },
            },
        },
        '2127': {
            name: '柳州',
            area: {
                '2128': {
                    name: '城中区',
                },
                '2129': {
                    name: '鱼峰区',
                },
                '2130': {
                    name: '柳南区',
                },
                '2131': {
                    name: '柳北区',
                },
                '2132': {
                    name: '柳江县',
                },
                '2133': {
                    name: '柳城县',
                },
                '2134': {
                    name: '鹿寨县',
                },
                '2135': {
                    name: '融安县',
                },
                '2136': {
                    name: '融水县',
                },
                '2137': {
                    name: '三江县',
                },
            },
        },
        '2138': {
            name: '桂林',
            area: {
                '2139': {
                    name: '秀峰区',
                },
                '2140': {
                    name: '叠彩区',
                },
                '2141': {
                    name: '象山区',
                },
                '2142': {
                    name: '七星区',
                },
                '2143': {
                    name: '雁山区',
                },
                '2144': {
                    name: '阳朔县',
                },
                '2145': {
                    name: '临桂县',
                },
                '2146': {
                    name: '灵川县',
                },
                '2147': {
                    name: '全州县',
                },
                '2148': {
                    name: '兴安县',
                },
                '2149': {
                    name: '永福县',
                },
                '2150': {
                    name: '灌阳县',
                },
                '2151': {
                    name: '龙胜县',
                },
                '2152': {
                    name: '资源县',
                },
                '2153': {
                    name: '平乐县',
                },
                '2154': {
                    name: '荔浦县',
                },
                '2155': {
                    name: '恭城县',
                },
            },
        },
        '2156': {
            name: '梧州',
            area: {
                '2157': {
                    name: '万秀区',
                },
                '2158': {
                    name: '蝶山区',
                },
                '2159': {
                    name: '长洲区',
                },
                '2160': {
                    name: '苍梧县',
                },
                '2161': {
                    name: '藤县',
                },
                '2162': {
                    name: '蒙山县',
                },
                '2163': {
                    name: '岑溪',
                },
            },
        },
        '2164': {
            name: '北海',
            area: {
                '2165': {
                    name: '海城区',
                },
                '2166': {
                    name: '银海区',
                },
                '2167': {
                    name: '铁山港区',
                },
                '2168': {
                    name: '合浦县',
                },
            },
        },
        '2169': {
            name: '防城港',
            area: {
                '2170': {
                    name: '港口区',
                },
                '2171': {
                    name: '防城区',
                },
                '2172': {
                    name: '上思县',
                },
                '2173': {
                    name: '东兴',
                },
            },
        },
        '2174': {
            name: '钦州',
            area: {
                '2175': {
                    name: '钦南区',
                },
                '2176': {
                    name: '钦北区',
                },
                '2177': {
                    name: '灵山县',
                },
                '2178': {
                    name: '浦北县',
                },
            },
        },
        '2179': {
            name: '贵港',
            area: {
                '2180': {
                    name: '港北区',
                },
                '2181': {
                    name: '港南区',
                },
                '2182': {
                    name: '覃塘区',
                },
                '2183': {
                    name: '平南县',
                },
                '2184': {
                    name: '桂平',
                },
            },
        },
        '2185': {
            name: '玉林',
            area: {
                '2186': {
                    name: '玉州区',
                },
                '2187': {
                    name: '容县',
                },
                '2188': {
                    name: '陆川县',
                },
                '2189': {
                    name: '博白县',
                },
                '2190': {
                    name: '兴业县',
                },
                '2191': {
                    name: '北流',
                },
            },
        },
        '2192': {
            name: '百色',
            area: {
                '2193': {
                    name: '右江区',
                },
                '2194': {
                    name: '田阳县',
                },
                '2195': {
                    name: '田东县',
                },
                '2196': {
                    name: '平果县',
                },
                '2197': {
                    name: '德保县',
                },
                '2198': {
                    name: '靖西县',
                },
                '2199': {
                    name: '那坡县',
                },
                '2200': {
                    name: '凌云县',
                },
                '2201': {
                    name: '乐业县',
                },
                '2202': {
                    name: '田林县',
                },
                '2203': {
                    name: '西林县',
                },
                '2204': {
                    name: '隆林县',
                },
            },
        },
        '2205': {
            name: '贺州',
            area: {
                '2206': {
                    name: '八步区',
                },
                '2207': {
                    name: '昭平县',
                },
                '2208': {
                    name: '钟山县',
                },
                '2209': {
                    name: '富川县',
                },
            },
        },
        '2210': {
            name: '河池',
            area: {
                '2211': {
                    name: '金城江区',
                },
                '2212': {
                    name: '南丹县',
                },
                '2213': {
                    name: '天峨县',
                },
                '2214': {
                    name: '凤山县',
                },
                '2215': {
                    name: '东兰县',
                },
                '2216': {
                    name: '罗城县',
                },
                '2217': {
                    name: '环江县',
                },
                '2218': {
                    name: '巴马县',
                },
                '2219': {
                    name: '都安县',
                },
                '2220': {
                    name: '大化县',
                },
                '2221': {
                    name: '宜州',
                },
            },
        },
        '2222': {
            name: '来宾',
            area: {
                '2223': {
                    name: '兴宾区',
                },
                '2224': {
                    name: '忻城县',
                },
                '2225': {
                    name: '象州县',
                },
                '2226': {
                    name: '武宣县',
                },
                '2227': {
                    name: '金秀县',
                },
                '2228': {
                    name: '合山',
                },
            },
        },
        '2229': {
            name: '崇左',
            area: {
                '2230': {
                    name: '江洲区',
                },
                '2231': {
                    name: '扶绥县',
                },
                '2232': {
                    name: '宁明县',
                },
                '2233': {
                    name: '龙州县',
                },
                '2234': {
                    name: '大新县',
                },
                '2235': {
                    name: '天等县',
                },
                '2236': {
                    name: '凭祥',
                },
            },
        },
    },
    '2237': {
        '2238': {
            name: '海口',
            area: {
                '2239': {
                    name: '秀英区',
                },
                '2240': {
                    name: '龙华区',
                },
                '2241': {
                    name: '琼山区',
                },
                '2242': {
                    name: '美兰区',
                },
            },
        },
        '2243': {
            name: '三亚',
        },
        '2244': {
            name: '三沙',
            area: {
                '2245': {
                    name: '西沙群岛',
                },
                '2246': {
                    name: '南沙群岛',
                },
                '2247': {
                    name: '中沙群岛的岛礁及其海域',
                },
            },
        },
        '2248': {
            name: '直辖市',
            area: {
                '2249': {
                    name: '五指山',
                },
                '2250': {
                    name: '琼海',
                },
                '2251': {
                    name: '儋州',
                },
                '2252': {
                    name: '文昌',
                },
                '2253': {
                    name: '万宁',
                },
                '2254': {
                    name: '东方',
                },
                '2255': {
                    name: '定安县',
                },
                '2256': {
                    name: '屯昌县',
                },
                '2257': {
                    name: '澄迈县',
                },
                '2258': {
                    name: '临高县',
                },
                '2259': {
                    name: '白沙县',
                },
                '2260': {
                    name: '昌江县',
                },
                '2261': {
                    name: '乐东县',
                },
                '2262': {
                    name: '陵水县',
                },
                '2263': {
                    name: '保亭县',
                },
                '2264': {
                    name: '琼中县',
                },
            },
        },
    },
    '2265': {
        '2266': {
            name: '重庆',
            area: {
                '2267': {
                    name: '万州区',
                },
                '2268': {
                    name: '涪陵区',
                },
                '2269': {
                    name: '渝中区',
                },
                '2270': {
                    name: '大渡口区',
                },
                '2271': {
                    name: '江北区',
                },
                '2272': {
                    name: '沙坪坝区',
                },
                '2273': {
                    name: '九龙坡区',
                },
                '2274': {
                    name: '南岸区',
                },
                '2275': {
                    name: '北碚区',
                },
                '2276': {
                    name: '綦江区',
                },
                '2277': {
                    name: '大足区',
                },
                '2278': {
                    name: '渝北区',
                },
                '2279': {
                    name: '巴南区',
                },
                '2280': {
                    name: '黔江区',
                },
                '2281': {
                    name: '长寿区',
                },
                '2282': {
                    name: '江津区',
                },
                '2283': {
                    name: '合川区',
                },
                '2284': {
                    name: '永川区',
                },
                '2285': {
                    name: '南川区',
                },
                '2286': {
                    name: '潼南县',
                },
                '2287': {
                    name: '铜梁县',
                },
                '2288': {
                    name: '荣昌县',
                },
                '2289': {
                    name: '璧山县',
                },
                '2290': {
                    name: '梁平县',
                },
                '2291': {
                    name: '城口县',
                },
                '2292': {
                    name: '丰都县',
                },
                '2293': {
                    name: '垫江县',
                },
                '2294': {
                    name: '武隆县',
                },
                '2295': {
                    name: '忠县',
                },
                '2296': {
                    name: '开县',
                },
                '2297': {
                    name: '云阳县',
                },
                '2298': {
                    name: '奉节县',
                },
                '2299': {
                    name: '巫山县',
                },
                '2300': {
                    name: '巫溪县',
                },
                '2301': {
                    name: '石柱县',
                },
                '2302': {
                    name: '秀山县',
                },
                '2303': {
                    name: '酉阳县',
                },
                '2304': {
                    name: '彭水县',
                },
            },
        },
    },
    '2305': {
        '2306': {
            name: '成都',
            area: {
                '2307': {
                    name: '锦江区',
                },
                '2308': {
                    name: '青羊区',
                },
                '2309': {
                    name: '金牛区',
                },
                '2310': {
                    name: '武侯区',
                },
                '2311': {
                    name: '成华区',
                },
                '2312': {
                    name: '龙泉驿区',
                },
                '2313': {
                    name: '青白江区',
                },
                '2314': {
                    name: '新都区',
                },
                '2315': {
                    name: '温江区',
                },
                '2316': {
                    name: '金堂县',
                },
                '2317': {
                    name: '双流县',
                },
                '2318': {
                    name: '郫县',
                },
                '2319': {
                    name: '大邑县',
                },
                '2320': {
                    name: '蒲江县',
                },
                '2321': {
                    name: '新津县',
                },
                '2322': {
                    name: '都江堰',
                },
                '2323': {
                    name: '彭州',
                },
                '2324': {
                    name: '邛崃',
                },
                '2325': {
                    name: '崇州',
                },
            },
        },
        '2326': {
            name: '自贡',
            area: {
                '2327': {
                    name: '自流井区',
                },
                '2328': {
                    name: '贡井区',
                },
                '2329': {
                    name: '大安区',
                },
                '2330': {
                    name: '沿滩区',
                },
                '2331': {
                    name: '荣县',
                },
                '2332': {
                    name: '富顺县',
                },
            },
        },
        '2333': {
            name: '攀枝花',
            area: {
                '2334': {
                    name: '东区',
                },
                '2335': {
                    name: '西区',
                },
                '2336': {
                    name: '仁和区',
                },
                '2337': {
                    name: '米易县',
                },
                '2338': {
                    name: '盐边县',
                },
            },
        },
        '2339': {
            name: '泸州',
            area: {
                '2340': {
                    name: '江阳区',
                },
                '2341': {
                    name: '纳溪区',
                },
                '2342': {
                    name: '龙马潭区',
                },
                '2343': {
                    name: '泸县',
                },
                '2344': {
                    name: '合江县',
                },
                '2345': {
                    name: '叙永县',
                },
                '2346': {
                    name: '古蔺县',
                },
            },
        },
        '2347': {
            name: '德阳',
            area: {
                '2348': {
                    name: '旌阳区',
                },
                '2349': {
                    name: '中江县',
                },
                '2350': {
                    name: '罗江县',
                },
                '2351': {
                    name: '广汉',
                },
                '2352': {
                    name: '什邡',
                },
                '2353': {
                    name: '绵竹',
                },
            },
        },
        '2354': {
            name: '绵阳',
            area: {
                '2355': {
                    name: '涪城区',
                },
                '2356': {
                    name: '游仙区',
                },
                '2357': {
                    name: '三台县',
                },
                '2358': {
                    name: '盐亭县',
                },
                '2359': {
                    name: '安县',
                },
                '2360': {
                    name: '梓潼县',
                },
                '2361': {
                    name: '北川县',
                },
                '2362': {
                    name: '平武县',
                },
                '2363': {
                    name: '江油',
                },
            },
        },
        '2364': {
            name: '广元',
            area: {
                '2365': {
                    name: '利州区',
                },
                '2366': {
                    name: '元坝区',
                },
                '2367': {
                    name: '朝天区',
                },
                '2368': {
                    name: '旺苍县',
                },
                '2369': {
                    name: '青川县',
                },
                '2370': {
                    name: '剑阁县',
                },
                '2371': {
                    name: '苍溪县',
                },
            },
        },
        '2372': {
            name: '遂宁',
            area: {
                '2373': {
                    name: '船山区',
                },
                '2374': {
                    name: '安居区',
                },
                '2375': {
                    name: '蓬溪县',
                },
                '2376': {
                    name: '射洪县',
                },
                '2377': {
                    name: '大英县',
                },
            },
        },
        '2378': {
            name: '内江',
            area: {
                '2379': {
                    name: '市中区',
                },
                '2380': {
                    name: '东兴区',
                },
                '2381': {
                    name: '威远县',
                },
                '2382': {
                    name: '资中县',
                },
                '2383': {
                    name: '隆昌县',
                },
            },
        },
        '2384': {
            name: '乐山',
            area: {
                '2385': {
                    name: '市中区',
                },
                '2386': {
                    name: '沙湾区',
                },
                '2387': {
                    name: '五通桥区',
                },
                '2388': {
                    name: '金口河区',
                },
                '2389': {
                    name: '犍为县',
                },
                '2390': {
                    name: '井研县',
                },
                '2391': {
                    name: '夹江县',
                },
                '2392': {
                    name: '沐川县',
                },
                '2393': {
                    name: '峨边县',
                },
                '2394': {
                    name: '马边县',
                },
                '2395': {
                    name: '峨眉山',
                },
            },
        },
        '2396': {
            name: '南充',
            area: {
                '2397': {
                    name: '顺庆区',
                },
                '2398': {
                    name: '高坪区',
                },
                '2399': {
                    name: '嘉陵区',
                },
                '2400': {
                    name: '南部县',
                },
                '2401': {
                    name: '营山县',
                },
                '2402': {
                    name: '蓬安县',
                },
                '2403': {
                    name: '仪陇县',
                },
                '2404': {
                    name: '西充县',
                },
                '2405': {
                    name: '阆中',
                },
            },
        },
        '2406': {
            name: '眉山',
            area: {
                '2407': {
                    name: '东坡区',
                },
                '2408': {
                    name: '仁寿县',
                },
                '2409': {
                    name: '彭山县',
                },
                '2410': {
                    name: '洪雅县',
                },
                '2411': {
                    name: '丹棱县',
                },
                '2412': {
                    name: '青神县',
                },
            },
        },
        '2413': {
            name: '宜宾',
            area: {
                '2414': {
                    name: '翠屏区',
                },
                '2415': {
                    name: '南溪区',
                },
                '2416': {
                    name: '宜宾县',
                },
                '2417': {
                    name: '江安县',
                },
                '2418': {
                    name: '长宁县',
                },
                '2419': {
                    name: '高县',
                },
                '2420': {
                    name: '珙县',
                },
                '2421': {
                    name: '筠连县',
                },
                '2422': {
                    name: '兴文县',
                },
                '2423': {
                    name: '屏山县',
                },
            },
        },
        '2424': {
            name: '广安',
            area: {
                '2425': {
                    name: '广安区',
                },
                '2426': {
                    name: '岳池县',
                },
                '2427': {
                    name: '武胜县',
                },
                '2428': {
                    name: '邻水县',
                },
                '2429': {
                    name: '华蓥',
                },
            },
        },
        '2430': {
            name: '达州',
            area: {
                '2431': {
                    name: '通川区',
                },
                '2432': {
                    name: '达县',
                },
                '2433': {
                    name: '宣汉县',
                },
                '2434': {
                    name: '开江县',
                },
                '2435': {
                    name: '大竹县',
                },
                '2436': {
                    name: '渠县',
                },
                '2437': {
                    name: '万源',
                },
            },
        },
        '2438': {
            name: '雅安',
            area: {
                '2439': {
                    name: '雨城区',
                },
                '2440': {
                    name: '名山区',
                },
                '2441': {
                    name: '荥经县',
                },
                '2442': {
                    name: '汉源县',
                },
                '2443': {
                    name: '石棉县',
                },
                '2444': {
                    name: '天全县',
                },
                '2445': {
                    name: '芦山县',
                },
                '2446': {
                    name: '宝兴县',
                },
            },
        },
        '2447': {
            name: '巴中',
            area: {
                '2448': {
                    name: '巴州区',
                },
                '2449': {
                    name: '通江县',
                },
                '2450': {
                    name: '南江县',
                },
                '2451': {
                    name: '平昌县',
                },
            },
        },
        '2452': {
            name: '资阳',
            area: {
                '2453': {
                    name: '雁江区',
                },
                '2454': {
                    name: '安岳县',
                },
                '2455': {
                    name: '乐至县',
                },
                '2456': {
                    name: '简阳',
                },
            },
        },
        '2457': {
            name: '阿坝',
            area: {
                '2458': {
                    name: '汶川县',
                },
                '2459': {
                    name: '理县',
                },
                '2460': {
                    name: '茂县',
                },
                '2461': {
                    name: '松潘县',
                },
                '2462': {
                    name: '九寨沟县',
                },
                '2463': {
                    name: '金川县',
                },
                '2464': {
                    name: '小金县',
                },
                '2465': {
                    name: '黑水县',
                },
                '2466': {
                    name: '马尔康县',
                },
                '2467': {
                    name: '壤塘县',
                },
                '2468': {
                    name: '阿坝县',
                },
                '2469': {
                    name: '若尔盖县',
                },
                '2470': {
                    name: '红原县',
                },
            },
        },
        '2471': {
            name: '甘孜',
            area: {
                '2472': {
                    name: '康定县',
                },
                '2473': {
                    name: '泸定县',
                },
                '2474': {
                    name: '丹巴县',
                },
                '2475': {
                    name: '九龙县',
                },
                '2476': {
                    name: '雅江县',
                },
                '2477': {
                    name: '道孚县',
                },
                '2478': {
                    name: '炉霍县',
                },
                '2479': {
                    name: '甘孜县',
                },
                '2480': {
                    name: '新龙县',
                },
                '2481': {
                    name: '德格县',
                },
                '2482': {
                    name: '白玉县',
                },
                '2483': {
                    name: '石渠县',
                },
                '2484': {
                    name: '色达县',
                },
                '2485': {
                    name: '理塘县',
                },
                '2486': {
                    name: '巴塘县',
                },
                '2487': {
                    name: '乡城县',
                },
                '2488': {
                    name: '稻城县',
                },
                '2489': {
                    name: '得荣县',
                },
            },
        },
        '2490': {
            name: '凉山',
            area: {
                '2491': {
                    name: '西昌',
                },
                '2492': {
                    name: '木里县',
                },
                '2493': {
                    name: '盐源县',
                },
                '2494': {
                    name: '德昌县',
                },
                '2495': {
                    name: '会理县',
                },
                '2496': {
                    name: '会东县',
                },
                '2497': {
                    name: '宁南县',
                },
                '2498': {
                    name: '普格县',
                },
                '2499': {
                    name: '布拖县',
                },
                '2500': {
                    name: '金阳县',
                },
                '2501': {
                    name: '昭觉县',
                },
                '2502': {
                    name: '喜德县',
                },
                '2503': {
                    name: '冕宁县',
                },
                '2504': {
                    name: '越西县',
                },
                '2505': {
                    name: '甘洛县',
                },
                '2506': {
                    name: '美姑县',
                },
                '2507': {
                    name: '雷波县',
                },
            },
        },
    },
    '2508': {
        '2509': {
            name: '贵阳',
            area: {
                '2510': {
                    name: '南明区',
                },
                '2511': {
                    name: '云岩区',
                },
                '2512': {
                    name: '花溪区',
                },
                '2513': {
                    name: '乌当区',
                },
                '2514': {
                    name: '白云区',
                },
                '2515': {
                    name: '小河区',
                },
                '2516': {
                    name: '开阳县',
                },
                '2517': {
                    name: '息烽县',
                },
                '2518': {
                    name: '修文县',
                },
                '2519': {
                    name: '清镇',
                },
            },
        },
        '2520': {
            name: '六盘水',
            area: {
                '2521': {
                    name: '钟山区',
                },
                '2522': {
                    name: '六枝特区',
                },
                '2523': {
                    name: '水城县',
                },
                '2524': {
                    name: '盘县',
                },
            },
        },
        '2525': {
            name: '遵义',
            area: {
                '2526': {
                    name: '红花岗区',
                },
                '2527': {
                    name: '汇川区',
                },
                '2528': {
                    name: '遵义县',
                },
                '2529': {
                    name: '桐梓县',
                },
                '2530': {
                    name: '绥阳县',
                },
                '2531': {
                    name: '正安县',
                },
                '2532': {
                    name: '道真县',
                },
                '2533': {
                    name: '务川县',
                },
                '2534': {
                    name: '凤冈县',
                },
                '2535': {
                    name: '湄潭县',
                },
                '2536': {
                    name: '余庆县',
                },
                '2537': {
                    name: '习水县',
                },
                '2538': {
                    name: '赤水',
                },
                '2539': {
                    name: '仁怀',
                },
            },
        },
        '2540': {
            name: '安顺',
            area: {
                '2541': {
                    name: '西秀区',
                },
                '2542': {
                    name: '平坝县',
                },
                '2543': {
                    name: '普定县',
                },
                '2544': {
                    name: '镇宁县',
                },
                '2545': {
                    name: '关岭县',
                },
                '2546': {
                    name: '紫云县',
                },
            },
        },
        '2547': {
            name: '毕节',
            area: {
                '2548': {
                    name: '七星关区',
                },
                '2549': {
                    name: '大方县',
                },
                '2550': {
                    name: '黔西县',
                },
                '2551': {
                    name: '金沙县',
                },
                '2552': {
                    name: '织金县',
                },
                '2553': {
                    name: '纳雍县',
                },
                '2554': {
                    name: '威宁县',
                },
                '2555': {
                    name: '赫章县',
                },
            },
        },
        '2556': {
            name: '铜仁',
            area: {
                '2557': {
                    name: '碧江区',
                },
                '2558': {
                    name: '万山区',
                },
                '2559': {
                    name: '江口县',
                },
                '2560': {
                    name: '玉屏县',
                },
                '2561': {
                    name: '石阡县',
                },
                '2562': {
                    name: '思南县',
                },
                '2563': {
                    name: '印江县',
                },
                '2564': {
                    name: '德江县',
                },
                '2565': {
                    name: '沿河县',
                },
                '2566': {
                    name: '松桃县',
                },
            },
        },
        '2567': {
            name: '黔西',
            area: {
                '2568': {
                    name: '兴义',
                },
                '2569': {
                    name: '兴仁县',
                },
                '2570': {
                    name: '普安县',
                },
                '2571': {
                    name: '晴隆县',
                },
                '2572': {
                    name: '贞丰县',
                },
                '2573': {
                    name: '望谟县',
                },
                '2574': {
                    name: '册亨县',
                },
                '2575': {
                    name: '安龙县',
                },
            },
        },
        '2576': {
            name: '黔东',
            area: {
                '2577': {
                    name: '凯里',
                },
                '2578': {
                    name: '黄平县',
                },
                '2579': {
                    name: '施秉县',
                },
                '2580': {
                    name: '三穗县',
                },
                '2581': {
                    name: '镇远县',
                },
                '2582': {
                    name: '岑巩县',
                },
                '2583': {
                    name: '天柱县',
                },
                '2584': {
                    name: '锦屏县',
                },
                '2585': {
                    name: '剑河县',
                },
                '2586': {
                    name: '台江县',
                },
                '2587': {
                    name: '黎平县',
                },
                '2588': {
                    name: '榕江县',
                },
                '2589': {
                    name: '从江县',
                },
                '2590': {
                    name: '雷山县',
                },
                '2591': {
                    name: '麻江县',
                },
                '2592': {
                    name: '丹寨县',
                },
            },
        },
        '2593': {
            name: '黔南',
            area: {
                '2594': {
                    name: '都匀',
                },
                '2595': {
                    name: '福泉',
                },
                '2596': {
                    name: '荔波县',
                },
                '2597': {
                    name: '贵定县',
                },
                '2598': {
                    name: '瓮安县',
                },
                '2599': {
                    name: '独山县',
                },
                '2600': {
                    name: '平塘县',
                },
                '2601': {
                    name: '罗甸县',
                },
                '2602': {
                    name: '长顺县',
                },
                '2603': {
                    name: '龙里县',
                },
                '2604': {
                    name: '惠水县',
                },
                '2605': {
                    name: '三都县',
                },
            },
        },
    },
    '2606': {
        '2607': {
            name: '昆明',
            area: {
                '2608': {
                    name: '五华区',
                },
                '2609': {
                    name: '盘龙区',
                },
                '2610': {
                    name: '官渡区',
                },
                '2611': {
                    name: '西山区',
                },
                '2612': {
                    name: '东川区',
                },
                '2613': {
                    name: '呈贡区',
                },
                '2614': {
                    name: '晋宁县',
                },
                '2615': {
                    name: '富民县',
                },
                '2616': {
                    name: '宜良县',
                },
                '2617': {
                    name: '石林县',
                },
                '2618': {
                    name: '嵩明县',
                },
                '2619': {
                    name: '禄劝县',
                },
                '2620': {
                    name: '寻甸县',
                },
                '2621': {
                    name: '安宁',
                },
            },
        },
        '2622': {
            name: '曲靖',
            area: {
                '2623': {
                    name: '麒麟区',
                },
                '2624': {
                    name: '马龙县',
                },
                '2625': {
                    name: '陆良县',
                },
                '2626': {
                    name: '师宗县',
                },
                '2627': {
                    name: '罗平县',
                },
                '2628': {
                    name: '富源县',
                },
                '2629': {
                    name: '会泽县',
                },
                '2630': {
                    name: '沾益县',
                },
                '2631': {
                    name: '宣威',
                },
            },
        },
        '2632': {
            name: '玉溪',
            area: {
                '2633': {
                    name: '红塔区',
                },
                '2634': {
                    name: '江川县',
                },
                '2635': {
                    name: '澄江县',
                },
                '2636': {
                    name: '通海县',
                },
                '2637': {
                    name: '华宁县',
                },
                '2638': {
                    name: '易门县',
                },
                '2639': {
                    name: '峨山县',
                },
                '2640': {
                    name: '新平县',
                },
                '2641': {
                    name: '元江县',
                },
            },
        },
        '2642': {
            name: '保山',
            area: {
                '2643': {
                    name: '隆阳区',
                },
                '2644': {
                    name: '施甸县',
                },
                '2645': {
                    name: '腾冲县',
                },
                '2646': {
                    name: '龙陵县',
                },
                '2647': {
                    name: '昌宁县',
                },
            },
        },
        '2648': {
            name: '昭通',
            area: {
                '2649': {
                    name: '昭阳区',
                },
                '2650': {
                    name: '鲁甸县',
                },
                '2651': {
                    name: '巧家县',
                },
                '2652': {
                    name: '盐津县',
                },
                '2653': {
                    name: '大关县',
                },
                '2654': {
                    name: '永善县',
                },
                '2655': {
                    name: '绥江县',
                },
                '2656': {
                    name: '镇雄县',
                },
                '2657': {
                    name: '彝良县',
                },
                '2658': {
                    name: '威信县',
                },
                '2659': {
                    name: '水富县',
                },
            },
        },
        '2660': {
            name: '丽江',
            area: {
                '2661': {
                    name: '古城区',
                },
                '2662': {
                    name: '玉龙县',
                },
                '2663': {
                    name: '永胜县',
                },
                '2664': {
                    name: '华坪县',
                },
                '2665': {
                    name: '宁蒗县',
                },
            },
        },
        '2666': {
            name: '普洱',
            area: {
                '2667': {
                    name: '思茅区',
                },
                '2668': {
                    name: '宁洱县',
                },
                '2669': {
                    name: '墨江县',
                },
                '2670': {
                    name: '景东县',
                },
                '2671': {
                    name: '景谷县',
                },
                '2672': {
                    name: '镇沅县',
                },
                '2673': {
                    name: '江城县',
                },
                '2674': {
                    name: '孟连县',
                },
                '2675': {
                    name: '澜沧县',
                },
                '2676': {
                    name: '西盟县',
                },
            },
        },
        '2677': {
            name: '临沧',
            area: {
                '2678': {
                    name: '临翔区',
                },
                '2679': {
                    name: '凤庆县',
                },
                '2680': {
                    name: '云县',
                },
                '2681': {
                    name: '永德县',
                },
                '2682': {
                    name: '镇康县',
                },
                '2683': {
                    name: '双江县',
                },
                '2684': {
                    name: '耿马县',
                },
                '2685': {
                    name: '沧源县',
                },
            },
        },
        '2686': {
            name: '楚雄',
            area: {
                '2687': {
                    name: '楚雄',
                },
                '2688': {
                    name: '双柏县',
                },
                '2689': {
                    name: '牟定县',
                },
                '2690': {
                    name: '南华县',
                },
                '2691': {
                    name: '姚安县',
                },
                '2692': {
                    name: '大姚县',
                },
                '2693': {
                    name: '永仁县',
                },
                '2694': {
                    name: '元谋县',
                },
                '2695': {
                    name: '武定县',
                },
                '2696': {
                    name: '禄丰县',
                },
            },
        },
        '2697': {
            name: '红河',
            area: {
                '2698': {
                    name: '个旧',
                },
                '2699': {
                    name: '开远',
                },
                '2700': {
                    name: '蒙自',
                },
                '2701': {
                    name: '屏边县',
                },
                '2702': {
                    name: '建水县',
                },
                '2703': {
                    name: '石屏县',
                },
                '2704': {
                    name: '弥勒县',
                },
                '2705': {
                    name: '泸西县',
                },
                '2706': {
                    name: '元阳县',
                },
                '2707': {
                    name: '红河县',
                },
                '2708': {
                    name: '金平县',
                },
                '2709': {
                    name: '绿春县',
                },
                '2710': {
                    name: '河口县',
                },
            },
        },
        '2711': {
            name: '文山',
            area: {
                '2712': {
                    name: '文山',
                },
                '2713': {
                    name: '砚山县',
                },
                '2714': {
                    name: '西畴县',
                },
                '2715': {
                    name: '麻栗坡县',
                },
                '2716': {
                    name: '马关县',
                },
                '2717': {
                    name: '丘北县',
                },
                '2718': {
                    name: '广南县',
                },
                '2719': {
                    name: '富宁县',
                },
            },
        },
        '2720': {
            name: '西双版纳',
            area: {
                '2721': {
                    name: '景洪',
                },
                '2722': {
                    name: '勐海县',
                },
                '2723': {
                    name: '勐腊县',
                },
            },
        },
        '2724': {
            name: '大理',
            area: {
                '2725': {
                    name: '大理',
                },
                '2726': {
                    name: '漾濞县',
                },
                '2727': {
                    name: '祥云县',
                },
                '2728': {
                    name: '宾川县',
                },
                '2729': {
                    name: '弥渡县',
                },
                '2730': {
                    name: '南涧县',
                },
                '2731': {
                    name: '巍山县',
                },
                '2732': {
                    name: '永平县',
                },
                '2733': {
                    name: '云龙县',
                },
                '2734': {
                    name: '洱源县',
                },
                '2735': {
                    name: '剑川县',
                },
                '2736': {
                    name: '鹤庆县',
                },
            },
        },
        '2737': {
            name: '德宏',
            area: {
                '2738': {
                    name: '瑞丽',
                },
                '2739': {
                    name: '芒',
                },
                '2740': {
                    name: '梁河县',
                },
                '2741': {
                    name: '盈江县',
                },
                '2742': {
                    name: '陇川县',
                },
            },
        },
        '2743': {
            name: '怒江',
            area: {
                '2744': {
                    name: '泸水县',
                },
                '2745': {
                    name: '福贡县',
                },
                '2746': {
                    name: '贡山县',
                },
                '2747': {
                    name: '兰坪县',
                },
            },
        },
        '2748': {
            name: '迪庆',
            area: {
                '2749': {
                    name: '香格里拉县',
                },
                '2750': {
                    name: '德钦县',
                },
                '2751': {
                    name: '维西县',
                },
            },
        },
    },
    '2752': {
        '2753': {
            name: '拉萨',
            area: {
                '2754': {
                    name: '城关区',
                },
                '2755': {
                    name: '林周县',
                },
                '2756': {
                    name: '当雄县',
                },
                '2757': {
                    name: '尼木县',
                },
                '2758': {
                    name: '曲水县',
                },
                '2759': {
                    name: '堆龙德庆县',
                },
                '2760': {
                    name: '达孜县',
                },
                '2761': {
                    name: '墨竹工卡县',
                },
            },
        },
        '2762': {
            name: '昌都',
            area: {
                '2763': {
                    name: '昌都县',
                },
                '2764': {
                    name: '江达县',
                },
                '2765': {
                    name: '贡觉县',
                },
                '2766': {
                    name: '类乌齐县',
                },
                '2767': {
                    name: '丁青县',
                },
                '2768': {
                    name: '察雅县',
                },
                '2769': {
                    name: '八宿县',
                },
                '2770': {
                    name: '左贡县',
                },
                '2771': {
                    name: '芒康县',
                },
                '2772': {
                    name: '洛隆县',
                },
                '2773': {
                    name: '边坝县',
                },
            },
        },
        '2774': {
            name: '山南',
            area: {
                '2775': {
                    name: '乃东县',
                },
                '2776': {
                    name: '扎囊县',
                },
                '2777': {
                    name: '贡嘎县',
                },
                '2778': {
                    name: '桑日县',
                },
                '2779': {
                    name: '琼结县',
                },
                '2780': {
                    name: '曲松县',
                },
                '2781': {
                    name: '措美县',
                },
                '2782': {
                    name: '洛扎县',
                },
                '2783': {
                    name: '加查县',
                },
                '2784': {
                    name: '隆子县',
                },
                '2785': {
                    name: '错那县',
                },
                '2786': {
                    name: '浪卡子县',
                },
            },
        },
        '2787': {
            name: '日喀则',
            area: {
                '2788': {
                    name: '日喀则',
                },
                '2789': {
                    name: '南木林县',
                },
                '2790': {
                    name: '江孜县',
                },
                '2791': {
                    name: '定日县',
                },
                '2792': {
                    name: '萨迦县',
                },
                '2793': {
                    name: '拉孜县',
                },
                '2794': {
                    name: '昂仁县',
                },
                '2795': {
                    name: '谢通门县',
                },
                '2796': {
                    name: '白朗县',
                },
                '2797': {
                    name: '仁布县',
                },
                '2798': {
                    name: '康马县',
                },
                '2799': {
                    name: '定结县',
                },
                '2800': {
                    name: '仲巴县',
                },
                '2801': {
                    name: '亚东县',
                },
                '2802': {
                    name: '吉隆县',
                },
                '2803': {
                    name: '聂拉木县',
                },
                '2804': {
                    name: '萨嘎县',
                },
                '2805': {
                    name: '岗巴县',
                },
            },
        },
        '2806': {
            name: '那曲',
            area: {
                '2807': {
                    name: '那曲县',
                },
                '2808': {
                    name: '嘉黎县',
                },
                '2809': {
                    name: '比如县',
                },
                '2810': {
                    name: '聂荣县',
                },
                '2811': {
                    name: '安多县',
                },
                '2812': {
                    name: '申扎县',
                },
                '2813': {
                    name: '索县',
                },
                '2814': {
                    name: '班戈县',
                },
                '2815': {
                    name: '巴青县',
                },
                '2816': {
                    name: '尼玛县',
                },
            },
        },
        '2817': {
            name: '阿里',
            area: {
                '2818': {
                    name: '普兰县',
                },
                '2819': {
                    name: '札达县',
                },
                '2820': {
                    name: '噶尔县',
                },
                '2821': {
                    name: '日土县',
                },
                '2822': {
                    name: '革吉县',
                },
                '2823': {
                    name: '改则县',
                },
                '2824': {
                    name: '措勤县',
                },
            },
        },
        '2825': {
            name: '林芝',
            area: {
                '2826': {
                    name: '林芝县',
                },
                '2827': {
                    name: '工布江达县',
                },
                '2828': {
                    name: '米林县',
                },
                '2829': {
                    name: '墨脱县',
                },
                '2830': {
                    name: '波密县',
                },
                '2831': {
                    name: '察隅县',
                },
                '2832': {
                    name: '朗县',
                },
            },
        },
    },
    '2833': {
        '2834': {
            name: '西安',
            area: {
                '2835': {
                    name: '新城区',
                },
                '2836': {
                    name: '碑林区',
                },
                '2837': {
                    name: '莲湖区',
                },
                '2838': {
                    name: '灞桥区',
                },
                '2839': {
                    name: '未央区',
                },
                '2840': {
                    name: '雁塔区',
                },
                '2841': {
                    name: '阎良区',
                },
                '2842': {
                    name: '临潼区',
                },
                '2843': {
                    name: '长安区',
                },
                '2844': {
                    name: '蓝田县',
                },
                '2845': {
                    name: '周至县',
                },
                '2846': {
                    name: '户县',
                },
                '2847': {
                    name: '高陵县',
                },
            },
        },
        '2848': {
            name: '铜川',
            area: {
                '2849': {
                    name: '王益区',
                },
                '2850': {
                    name: '印台区',
                },
                '2851': {
                    name: '耀州区',
                },
                '2852': {
                    name: '宜君县',
                },
            },
        },
        '2853': {
            name: '宝鸡',
            area: {
                '2854': {
                    name: '渭滨区',
                },
                '2855': {
                    name: '金台区',
                },
                '2856': {
                    name: '陈仓区',
                },
                '2857': {
                    name: '凤翔县',
                },
                '2858': {
                    name: '岐山县',
                },
                '2859': {
                    name: '扶风县',
                },
                '2860': {
                    name: '眉县',
                },
                '2861': {
                    name: '陇县',
                },
                '2862': {
                    name: '千阳县',
                },
                '2863': {
                    name: '麟游县',
                },
                '2864': {
                    name: '凤县',
                },
                '2865': {
                    name: '太白县',
                },
            },
        },
        '2866': {
            name: '咸阳',
            area: {
                '2867': {
                    name: '秦都区',
                },
                '2868': {
                    name: '杨陵区',
                },
                '2869': {
                    name: '渭城区',
                },
                '2870': {
                    name: '三原县',
                },
                '2871': {
                    name: '泾阳县',
                },
                '2872': {
                    name: '乾县',
                },
                '2873': {
                    name: '礼泉县',
                },
                '2874': {
                    name: '永寿县',
                },
                '2875': {
                    name: '彬县',
                },
                '2876': {
                    name: '长武县',
                },
                '2877': {
                    name: '旬邑县',
                },
                '2878': {
                    name: '淳化县',
                },
                '2879': {
                    name: '武功县',
                },
                '2880': {
                    name: '兴平',
                },
            },
        },
        '2881': {
            name: '渭南',
            area: {
                '2882': {
                    name: '临渭区',
                },
                '2883': {
                    name: '华县',
                },
                '2884': {
                    name: '潼关县',
                },
                '2885': {
                    name: '大荔县',
                },
                '2886': {
                    name: '合阳县',
                },
                '2887': {
                    name: '澄城县',
                },
                '2888': {
                    name: '蒲城县',
                },
                '2889': {
                    name: '白水县',
                },
                '2890': {
                    name: '富平县',
                },
                '2891': {
                    name: '韩城',
                },
                '2892': {
                    name: '华阴',
                },
            },
        },
        '2893': {
            name: '延安',
            area: {
                '2894': {
                    name: '宝塔区',
                },
                '2895': {
                    name: '延长县',
                },
                '2896': {
                    name: '延川县',
                },
                '2897': {
                    name: '子长县',
                },
                '2898': {
                    name: '安塞县',
                },
                '2899': {
                    name: '志丹县',
                },
                '2900': {
                    name: '吴起县',
                },
                '2901': {
                    name: '甘泉县',
                },
                '2902': {
                    name: '富县',
                },
                '2903': {
                    name: '洛川县',
                },
                '2904': {
                    name: '宜川县',
                },
                '2905': {
                    name: '黄龙县',
                },
                '2906': {
                    name: '黄陵县',
                },
            },
        },
        '2907': {
            name: '汉中',
            area: {
                '2908': {
                    name: '汉台区',
                },
                '2909': {
                    name: '南郑县',
                },
                '2910': {
                    name: '城固县',
                },
                '2911': {
                    name: '洋县',
                },
                '2912': {
                    name: '西乡县',
                },
                '2913': {
                    name: '勉县',
                },
                '2914': {
                    name: '宁强县',
                },
                '2915': {
                    name: '略阳县',
                },
                '2916': {
                    name: '镇巴县',
                },
                '2917': {
                    name: '留坝县',
                },
                '2918': {
                    name: '佛坪县',
                },
            },
        },
        '2919': {
            name: '榆林',
            area: {
                '2920': {
                    name: '榆阳区',
                },
                '2921': {
                    name: '神木县',
                },
                '2922': {
                    name: '府谷县',
                },
                '2923': {
                    name: '横山县',
                },
                '2924': {
                    name: '靖边县',
                },
                '2925': {
                    name: '定边县',
                },
                '2926': {
                    name: '绥德县',
                },
                '2927': {
                    name: '米脂县',
                },
                '2928': {
                    name: '佳县',
                },
                '2929': {
                    name: '吴堡县',
                },
                '2930': {
                    name: '清涧县',
                },
                '2931': {
                    name: '子洲县',
                },
            },
        },
        '2932': {
            name: '安康',
            area: {
                '2933': {
                    name: '汉滨区',
                },
                '2934': {
                    name: '汉阴县',
                },
                '2935': {
                    name: '石泉县',
                },
                '2936': {
                    name: '宁陕县',
                },
                '2937': {
                    name: '紫阳县',
                },
                '2938': {
                    name: '岚皋县',
                },
                '2939': {
                    name: '平利县',
                },
                '2940': {
                    name: '镇坪县',
                },
                '2941': {
                    name: '旬阳县',
                },
                '2942': {
                    name: '白河县',
                },
            },
        },
        '2943': {
            name: '商洛',
            area: {
                '2944': {
                    name: '商州区',
                },
                '2945': {
                    name: '洛南县',
                },
                '2946': {
                    name: '丹凤县',
                },
                '2947': {
                    name: '商南县',
                },
                '2948': {
                    name: '山阳县',
                },
                '2949': {
                    name: '镇安县',
                },
                '2950': {
                    name: '柞水县',
                },
            },
        },
    },
    '2951': {
        '2952': {
            name: '兰州',
            area: {
                '2953': {
                    name: '城关区',
                },
                '2954': {
                    name: '七里河区',
                },
                '2955': {
                    name: '西固区',
                },
                '2956': {
                    name: '安宁区',
                },
                '2957': {
                    name: '红古区',
                },
                '2958': {
                    name: '永登县',
                },
                '2959': {
                    name: '皋兰县',
                },
                '2960': {
                    name: '榆中县',
                },
            },
        },
        '2961': {
            name: '嘉峪关',
        },
        '2962': {
            name: '金昌',
            area: {
                '2963': {
                    name: '金川区',
                },
                '2964': {
                    name: '永昌县',
                },
            },
        },
        '2965': {
            name: '白银',
            area: {
                '2966': {
                    name: '白银区',
                },
                '2967': {
                    name: '平川区',
                },
                '2968': {
                    name: '靖远县',
                },
                '2969': {
                    name: '会宁县',
                },
                '2970': {
                    name: '景泰县',
                },
            },
        },
        '2971': {
            name: '天水',
            area: {
                '2972': {
                    name: '秦州区',
                },
                '2973': {
                    name: '麦积区',
                },
                '2974': {
                    name: '清水县',
                },
                '2975': {
                    name: '秦安县',
                },
                '2976': {
                    name: '甘谷县',
                },
                '2977': {
                    name: '武山县',
                },
                '2978': {
                    name: '张家川',
                },
            },
        },
        '2979': {
            name: '武威',
            area: {
                '2980': {
                    name: '凉州区',
                },
                '2981': {
                    name: '民勤县',
                },
                '2982': {
                    name: '古浪县',
                },
                '2983': {
                    name: '天祝县',
                },
            },
        },
        '2984': {
            name: '张掖',
            area: {
                '2985': {
                    name: '甘州区',
                },
                '2986': {
                    name: '肃南县',
                },
                '2987': {
                    name: '民乐县',
                },
                '2988': {
                    name: '临泽县',
                },
                '2989': {
                    name: '高台县',
                },
                '2990': {
                    name: '山丹县',
                },
            },
        },
        '2991': {
            name: '平凉',
            area: {
                '2992': {
                    name: '崆峒区',
                },
                '2993': {
                    name: '泾川县',
                },
                '2994': {
                    name: '灵台县',
                },
                '2995': {
                    name: '崇信县',
                },
                '2996': {
                    name: '华亭县',
                },
                '2997': {
                    name: '庄浪县',
                },
                '2998': {
                    name: '静宁县',
                },
            },
        },
        '2999': {
            name: '酒泉',
            area: {
                '3000': {
                    name: '肃州区',
                },
                '3001': {
                    name: '金塔县',
                },
                '3002': {
                    name: '瓜州县',
                },
                '3003': {
                    name: '肃北县',
                },
                '3004': {
                    name: '阿克塞',
                },
                '3005': {
                    name: '玉门',
                },
                '3006': {
                    name: '敦煌',
                },
            },
        },
        '3007': {
            name: '庆阳',
            area: {
                '3008': {
                    name: '西峰区',
                },
                '3009': {
                    name: '庆城县',
                },
                '3010': {
                    name: '环县',
                },
                '3011': {
                    name: '华池县',
                },
                '3012': {
                    name: '合水县',
                },
                '3013': {
                    name: '正宁县',
                },
                '3014': {
                    name: '宁县',
                },
                '3015': {
                    name: '镇原县',
                },
            },
        },
        '3016': {
            name: '定西',
            area: {
                '3017': {
                    name: '安定区',
                },
                '3018': {
                    name: '通渭县',
                },
                '3019': {
                    name: '陇西县',
                },
                '3020': {
                    name: '渭源县',
                },
                '3021': {
                    name: '临洮县',
                },
                '3022': {
                    name: '漳县',
                },
                '3023': {
                    name: '岷县',
                },
            },
        },
        '3024': {
            name: '陇南',
            area: {
                '3025': {
                    name: '武都区',
                },
                '3026': {
                    name: '成县',
                },
                '3027': {
                    name: '文县',
                },
                '3028': {
                    name: '宕昌县',
                },
                '3029': {
                    name: '康县',
                },
                '3030': {
                    name: '西和县',
                },
                '3031': {
                    name: '礼县',
                },
                '3032': {
                    name: '徽县',
                },
                '3033': {
                    name: '两当县',
                },
            },
        },
        '3034': {
            name: '临夏',
            area: {
                '3035': {
                    name: '临夏',
                },
                '3036': {
                    name: '临夏县',
                },
                '3037': {
                    name: '康乐县',
                },
                '3038': {
                    name: '永靖县',
                },
                '3039': {
                    name: '广河县',
                },
                '3040': {
                    name: '和政县',
                },
                '3041': {
                    name: '东乡',
                },
                '3042': {
                    name: '积石山',
                },
            },
        },
        '3043': {
            name: '甘南',
            area: {
                '3044': {
                    name: '合作',
                },
                '3045': {
                    name: '临潭县',
                },
                '3046': {
                    name: '卓尼县',
                },
                '3047': {
                    name: '舟曲县',
                },
                '3048': {
                    name: '迭部县',
                },
                '3049': {
                    name: '玛曲县',
                },
                '3050': {
                    name: '碌曲县',
                },
                '3051': {
                    name: '夏河县',
                },
            },
        },
    },
    '3052': {
        '3053': {
            name: '西宁',
            area: {
                '3054': {
                    name: '城东区',
                },
                '3055': {
                    name: '城中区',
                },
                '3056': {
                    name: '城西区',
                },
                '3057': {
                    name: '城北区',
                },
                '3058': {
                    name: '大通县',
                },
                '3059': {
                    name: '湟中县',
                },
                '3060': {
                    name: '湟源县',
                },
            },
        },
        '3061': {
            name: '海东',
            area: {
                '3062': {
                    name: '平安县',
                },
                '3063': {
                    name: '民和县',
                },
                '3064': {
                    name: '乐都县',
                },
                '3065': {
                    name: '互助县',
                },
                '3066': {
                    name: '化隆县',
                },
                '3067': {
                    name: '循化县',
                },
            },
        },
        '3068': {
            name: '海北',
            area: {
                '3069': {
                    name: '门源县',
                },
                '3070': {
                    name: '祁连县',
                },
                '3071': {
                    name: '海晏县',
                },
                '3072': {
                    name: '刚察县',
                },
            },
        },
        '3073': {
            name: '黄南',
            area: {
                '3074': {
                    name: '同仁县',
                },
                '3075': {
                    name: '尖扎县',
                },
                '3076': {
                    name: '泽库县',
                },
                '3077': {
                    name: '河南',
                },
            },
        },
        '3078': {
            name: '海南',
            area: {
                '3079': {
                    name: '共和县',
                },
                '3080': {
                    name: '同德县',
                },
                '3081': {
                    name: '贵德县',
                },
                '3082': {
                    name: '兴海县',
                },
                '3083': {
                    name: '贵南县',
                },
            },
        },
        '3084': {
            name: '果洛',
            area: {
                '3085': {
                    name: '玛沁县',
                },
                '3086': {
                    name: '班玛县',
                },
                '3087': {
                    name: '甘德县',
                },
                '3088': {
                    name: '达日县',
                },
                '3089': {
                    name: '久治县',
                },
                '3090': {
                    name: '玛多县',
                },
            },
        },
        '3091': {
            name: '玉树',
            area: {
                '3092': {
                    name: '玉树县',
                },
                '3093': {
                    name: '杂多县',
                },
                '3094': {
                    name: '称多县',
                },
                '3095': {
                    name: '治多县',
                },
                '3096': {
                    name: '囊谦县',
                },
                '3097': {
                    name: '曲麻莱县',
                },
            },
        },
        '3098': {
            name: '海西',
            area: {
                '3099': {
                    name: '格尔木',
                },
                '3100': {
                    name: '德令哈',
                },
                '3101': {
                    name: '乌兰县',
                },
                '3102': {
                    name: '都兰县',
                },
                '3103': {
                    name: '天峻县',
                },
            },
        },
    },
    '3104': {
        '3105': {
            name: '银川',
            area: {
                '3106': {
                    name: '兴庆区',
                },
                '3107': {
                    name: '西夏区',
                },
                '3108': {
                    name: '金凤区',
                },
                '3109': {
                    name: '永宁县',
                },
                '3110': {
                    name: '贺兰县',
                },
                '3111': {
                    name: '灵武',
                },
            },
        },
        '3112': {
            name: '石嘴山',
            area: {
                '3113': {
                    name: '大武口区',
                },
                '3114': {
                    name: '惠农区',
                },
                '3115': {
                    name: '平罗县',
                },
            },
        },
        '3116': {
            name: '吴忠',
            area: {
                '3117': {
                    name: '利通区',
                },
                '3118': {
                    name: '红寺堡区',
                },
                '3119': {
                    name: '盐池县',
                },
                '3120': {
                    name: '同心县',
                },
                '3121': {
                    name: '青铜峡',
                },
            },
        },
        '3122': {
            name: '固原',
            area: {
                '3123': {
                    name: '原州区',
                },
                '3124': {
                    name: '西吉县',
                },
                '3125': {
                    name: '隆德县',
                },
                '3126': {
                    name: '泾源县',
                },
                '3127': {
                    name: '彭阳县',
                },
            },
        },
        '3128': {
            name: '中卫',
            area: {
                '3129': {
                    name: '沙坡头区',
                },
                '3130': {
                    name: '中宁县',
                },
                '3131': {
                    name: '海原县',
                },
            },
        },
    },
    '3132': {
        '3133': {
            name: '乌鲁木齐',
            area: {
                '3134': {
                    name: '天山区',
                },
                '3135': {
                    name: '沙依巴克区',
                },
                '3136': {
                    name: '新市区',
                },
                '3137': {
                    name: '水磨沟区',
                },
                '3138': {
                    name: '头屯河区',
                },
                '3139': {
                    name: '达坂城区',
                },
                '3140': {
                    name: '米东区',
                },
                '3141': {
                    name: '乌鲁木齐县',
                },
            },
        },
        '3142': {
            name: '克拉玛依',
            area: {
                '3143': {
                    name: '独山子区',
                },
                '3144': {
                    name: '克拉玛依区',
                },
                '3145': {
                    name: '白碱滩区',
                },
                '3146': {
                    name: '乌尔禾区',
                },
            },
        },
        '3147': {
            name: '吐鲁番',
            area: {
                '3148': {
                    name: '吐鲁番',
                },
                '3149': {
                    name: '鄯善县',
                },
                '3150': {
                    name: '托克逊县',
                },
            },
        },
        '3151': {
            name: '哈密',
            area: {
                '3152': {
                    name: '哈密',
                },
                '3153': {
                    name: '巴里坤哈萨克自治县',
                },
                '3154': {
                    name: '伊吾县',
                },
            },
        },
        '3155': {
            name: '昌吉',
            area: {
                '3156': {
                    name: '昌吉',
                },
                '3157': {
                    name: '阜康',
                },
                '3158': {
                    name: '呼图壁县',
                },
                '3159': {
                    name: '玛纳斯县',
                },
                '3160': {
                    name: '奇台县',
                },
                '3161': {
                    name: '吉木萨尔县',
                },
                '3162': {
                    name: '木垒哈萨克自治县',
                },
            },
        },
        '3163': {
            name: '博尔塔拉',
            area: {
                '3164': {
                    name: '博乐',
                },
                '3165': {
                    name: '精河县',
                },
                '3166': {
                    name: '温泉县',
                },
            },
        },
        '3167': {
            name: '巴音郭楞',
            area: {
                '3168': {
                    name: '库尔勒',
                },
                '3169': {
                    name: '轮台县',
                },
                '3170': {
                    name: '尉犁县',
                },
                '3171': {
                    name: '若羌县',
                },
                '3172': {
                    name: '且末县',
                },
                '3173': {
                    name: '焉耆县',
                },
                '3174': {
                    name: '和静县',
                },
                '3175': {
                    name: '和硕县',
                },
                '3176': {
                    name: '博湖县',
                },
            },
        },
        '3177': {
            name: '阿克苏',
            area: {
                '3178': {
                    name: '阿克苏',
                },
                '3179': {
                    name: '温宿县',
                },
                '3180': {
                    name: '库车县',
                },
                '3181': {
                    name: '沙雅县',
                },
                '3182': {
                    name: '新和县',
                },
                '3183': {
                    name: '拜城县',
                },
                '3184': {
                    name: '乌什县',
                },
                '3185': {
                    name: '阿瓦提县',
                },
                '3186': {
                    name: '柯坪县',
                },
            },
        },
        '3187': {
            name: '克孜勒苏',
            area: {
                '3188': {
                    name: '阿图什',
                },
                '3189': {
                    name: '阿克陶县',
                },
                '3190': {
                    name: '阿合奇县',
                },
                '3191': {
                    name: '乌恰县',
                },
            },
        },
        '3192': {
            name: '喀什',
            area: {
                '3193': {
                    name: '喀什',
                },
                '3194': {
                    name: '疏附县',
                },
                '3195': {
                    name: '疏勒县',
                },
                '3196': {
                    name: '英吉沙县',
                },
                '3197': {
                    name: '泽普县',
                },
                '3198': {
                    name: '莎车县',
                },
                '3199': {
                    name: '叶城县',
                },
                '3200': {
                    name: '麦盖提县',
                },
                '3201': {
                    name: '岳普湖县',
                },
                '3202': {
                    name: '伽师县',
                },
                '3203': {
                    name: '巴楚县',
                },
                '3204': {
                    name: '塔什库尔干塔吉克自治县',
                },
            },
        },
        '3205': {
            name: '和田',
            area: {
                '3206': {
                    name: '和田',
                },
                '3207': {
                    name: '和田县',
                },
                '3208': {
                    name: '墨玉县',
                },
                '3209': {
                    name: '皮山县',
                },
                '3210': {
                    name: '洛浦县',
                },
                '3211': {
                    name: '策勒县',
                },
                '3212': {
                    name: '于田县',
                },
                '3213': {
                    name: '民丰县',
                },
            },
        },
        '3214': {
            name: '伊犁',
            area: {
                '3215': {
                    name: '伊宁',
                },
                '3216': {
                    name: '奎屯',
                },
                '3217': {
                    name: '伊宁县',
                },
                '3218': {
                    name: '察布查尔锡伯自治县',
                },
                '3219': {
                    name: '霍城县',
                },
                '3220': {
                    name: '巩留县',
                },
                '3221': {
                    name: '新源县',
                },
                '3222': {
                    name: '昭苏县',
                },
                '3223': {
                    name: '特克斯县',
                },
                '3224': {
                    name: '尼勒克县',
                },
            },
        },
        '3225': {
            name: '塔城',
            area: {
                '3226': {
                    name: '塔城',
                },
                '3227': {
                    name: '乌苏',
                },
                '3228': {
                    name: '额敏县',
                },
                '3229': {
                    name: '沙湾县',
                },
                '3230': {
                    name: '托里县',
                },
                '3231': {
                    name: '裕民县',
                },
                '3232': {
                    name: '和布克赛尔',
                },
            },
        },
        '3233': {
            name: '阿勒泰',
            area: {
                '3234': {
                    name: '阿勒泰',
                },
                '3235': {
                    name: '布尔津县',
                },
                '3236': {
                    name: '富蕴县',
                },
                '3237': {
                    name: '福海县',
                },
                '3238': {
                    name: '哈巴河县',
                },
                '3239': {
                    name: '青河县',
                },
                '3240': {
                    name: '吉木乃县',
                },
            },
        },
        '3241': {
            name: '直辖市',
            area: {
                '3242': {
                    name: '石河子',
                },
                '3243': {
                    name: '阿拉尔',
                },
                '3244': {
                    name: '图木舒克',
                },
                '3245': {
                    name: '五家渠',
                },
            },
        },
    },
    '3307': {
        '3262': {
            name: '香港',
            area: {
                '3265': {
                    name: '沙田区',
                },
                '3266': {
                    name: '东区',
                },
                '3267': {
                    name: '观塘区',
                },
                '3268': {
                    name: '黄大仙区',
                },
                '3269': {
                    name: '九龙城区',
                },
                '3270': {
                    name: '屯门区',
                },
                '3271': {
                    name: '葵青区',
                },
                '3272': {
                    name: '元朗区',
                },
                '3273': {
                    name: '深水埗区',
                },
                '3274': {
                    name: '西贡区',
                },
                '3275': {
                    name: '大埔区',
                },
                '3276': {
                    name: '湾仔区',
                },
                '3277': {
                    name: '油尖旺区',
                },
                '3278': {
                    name: '北区',
                },
                '3279': {
                    name: '南区',
                },
                '3280': {
                    name: '荃湾区',
                },
                '3281': {
                    name: '中西区',
                },
                '3282': {
                    name: '离岛区',
                },
            },
        },
    },
    '3308': {
        '3263': {
            name: '澳门',
            area: {
                '3300': {
                    name: '花地玛堂区',
                },
                '3301': {
                    name: '圣安多尼堂区',
                },
                '3302': {
                    name: '大堂区',
                },
                '3303': {
                    name: '望德堂区',
                },
                '3304': {
                    name: '风顺堂区',
                },
                '3305': {
                    name: '氹仔',
                },
                '3306': {
                    name: '路环',
                },
            },
        },
    },
    '3309': {
        '3264': {
            name: '台湾',
            area: {
                '3283': {
                    name: '台北',
                },
                '3284': {
                    name: '高雄',
                },
                '3285': {
                    name: '基隆',
                },
                '3286': {
                    name: '台中',
                },
                '3287': {
                    name: '台南',
                },
                '3288': {
                    name: '新竹',
                },
                '3289': {
                    name: '嘉义',
                },
                '3290': {
                    name: '宜兰县',
                },
                '3291': {
                    name: '桃园县',
                },
                '3292': {
                    name: '苗栗县',
                },
                '3293': {
                    name: '彰化县',
                },
                '3294': {
                    name: '南投县',
                },
                '3295': {
                    name: '云林县',
                },
                '3296': {
                    name: '屏东县',
                },
                '3297': {
                    name: '台东县',
                },
                '3298': {
                    name: '花莲县',
                },
                '3299': {
                    name: '澎湖县',
                },
            },
        },
    },
};

export default obj;
