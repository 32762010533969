<template>
    <div class="relative">
        <div
            class="picker-button br-4 pv-15 ph-16 flex items-center relative"
            :class="{ default: isDefaultStyle }"
        >
            <slot></slot>
            <div
                class="flex-1 bg-transparent bn truncate f-16 lh-1 pa-0 ma-0 mr-10"
                @click="onClick"
            >
                {{ value || placeholder }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PickerButton',

    props: {
        placeholder: {
            type: String,
            default: '',
        },

        value: {
            type: String,
            default: '',
        },
    },

    computed: {
        isDefaultStyle() {
            return this.value === '';
        },
    },

    methods: {
        onClick() {
            // 防止点击只读输入框，出现在 iOS 上出现底部系统弹框
            // 参考 https://blog.csdn.net/weixin_40667880/article/details/103610258
            document.activeElement.blur();
        },
    },
};
</script>

<style scoped>
.picker-button {
    position: relative;
    color: #333;
}

.picker-button.default {
    color: #333;
}

.picker-button::after {
    content: ' ';
    width: 8px;
    height: 8px;
    margin-top: -4px;
    border: 1px solid #333;
    border-left-width: 0;
    border-top-width: 0;
    transform: rotate(45deg);
}
</style>
