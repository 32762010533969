// @ts-check
import axios from 'axios';
import store from 'store';

/** 上传图片的接口 */
const uploadUrl = APIBasePath + 'mmc/oss/upload';

/**
 * 上传图片
 * @param {{ onprogress: (e: ProgressEvent) => void, file: File }} params
 * @returns {Promise<string|void>}
 */
export function uploadImage(params) {
    var localTokenAll = store.get('Mmc-Token');
    var token = localTokenAll ? JSON.parse(localTokenAll).access_token : '';
    const config = {
        onUploadProgress: params.onprogress,
        headers: {
            'Content-Type': 'multipart/form-data;charset=utf-8',
            "Mmc-Token": token,
            Platform: 'mmc_h5',
        },
    };

    const { file } = params;
    const formData = new FormData();
    formData.append('files', file);

    return axios.post(uploadUrl, formData, config).then(onUploadHandler);
}

/**
 * 处理上传成功
 * @param {{ data: { code: number, msg: string, data?: string[] } }} res
 */
function onUploadHandler(res) {
    const { data, code, msg } = res.data;
    if (code === 0 && data && data[0]) {
        return data[0];
    }

    throw new Error(msg || '上传图片错误');
}
