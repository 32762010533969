<template>
    <div class="address-picker">
        <PickerButton
            :placeholder="placeholder"
            :value="address"
            @click.native="onClickButton"
        />
    </div>
</template>

<script>
import TreePicker from './js/TreePicker.js';
import PickerButton from '../PickerButton/index.vue';
import city from './js/city.js';
import { getAddressById } from './js/index.js';

/** @type {TreePicker} */
let treePicker;

export default {
    name: 'AddressPicker',

    components: {
        PickerButton,
    },

    props: {
        /** 城市 ID */
        city: {
            type: Number,
            default: 0,
        },

        /** 省份 ID */
        province: {
            type: Number,
            default: 0,
        },

        placeholder: {
            type: String,
            default: '选择省市',
        },
    },

    data() {
        return {};
    },

    computed: {
        address() {
            return getAddressById(this.province, this.city);
        },
    },

    mounted() {
        this.initPicker();
    },

    methods: {
        initPicker() {
            const data = TreePicker.parseQscArea(city.provinces, city.source);

            treePicker = new TreePicker({
                data,
                numCols: 2,
                title: '选择地址',
                selectedValue: [this.province, this.city],
                onselect: this.onSelect,
            });
        },

        /**
         * 下拉框改变监听函数
         * @param {Array<{ text: string, value: string }>} e
         */
        onSelect(e) {
            const [province, city] = e;
            const provinceId = Number(province.value);
            const provinceName = province.text;
            const cityId = Number(city.value);
            const cityName = city.text;

            this.$emit('change', {
                provinceId,
                cityId,
                provinceName,
                cityName,
            });
        },

        onClickButton() {
            treePicker.show();
        },
    },
};
</script>

<style>
.picker .picker-panel .wheel-wrapper .wheel .wheel-scroll .wheel-item {
    font-size: 16px;
}

.picker .picker-panel .picker-choose .picker-title {
    font-size: 15px !important;
    color: #5a4b41;
    font-weight: normal;
}
</style>
