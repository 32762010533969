import mmcAjax from '@/common/js/mmcAjax.js';

/** 项目验证 API 基础地址 */
const REVIEW_ROOT = APIBasePath + 'mmc/withdrawals';

/**
 * 按照省份筛选医院
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456198
 * @param {number} provinceId 省份 Id
 * @returns {Promise<mmc.Response<{list: Array<{id: number, name: string}>}>>}
 */
export function getHospitalByProvince(provinceId) {
    const url = `${REVIEW_ROOT}/hospital/list/${provinceId}`;
    return mmcAjax({ method: 'get', url });
}

/**
 * 获取已提交的患者信息
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455852
 * @param {string} uuid 项目 uuid
 * @returns {Promise<mmc.Response<mmc.review.aided.Api>>}
 */
export function getAidedInfo(uuid) {
    const url = `${REVIEW_ROOT}/patient/info/${uuid}`;
    return mmcAjax({ method: 'get', url });
}

/**
 * 提交患者信息
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455496
 * @param {mmc.review.aided.Api} params
 * @return {Promise<any>}
 */
export function submitAidedInfo(params) {
    const url = `${REVIEW_ROOT}/patient/set`;
    return mmcAjax({ method: 'post', url, data: params });
}

/**
 * 获取收款人验证信息
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455860
 * @param {string} uuid 项目 uuid
 * @returns {Promise<mmc.Response<mmc.review.payee.Response>>}
 */
export function getPayeeInfo(uuid) {
    const url = `${REVIEW_ROOT}/payee/info/${uuid}`;
    return mmcAjax({ method: 'get', url });
}

/**
 * 提交收款人信息
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93455499
 * @param {mmc.review.payee.Request} params
 * @return {Promise<mmc.Response<null>>}
 */
export function submitPayeeInfo(params) {
    const url = `${REVIEW_ROOT}/payee/set`;
    return mmcAjax({ method: 'post', url, data: params });
}

/**
 * 获取所有的银行卡列表
 * @link https://qscwiki001.qingsongchou.com/pages/viewpage.action?pageId=93456189
 * @returns {Promise<mmc.Response<any>>}
 */
export function getBankList() {
    const url = `${REVIEW_ROOT}/bank/list`;
    return mmcAjax({ method: 'get', url });
}
