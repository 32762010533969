<template>
    <div class="uploader">
        <input
            ref="upload-btn"
            type="file"
            accept="image/*"
            class="invisible"
            @change="onSelectFile"
        />

        <!-- 尚未上传 -->
        <div
            v-if="status === 0"
            class="h-100 flex flex-column items-center justify-center"
            @click="onUpload"
        >
            <div class="cross"></div>
            <div class="tc blue mt-10 f6 pre">{{ label }}</div>
        </div>

        <!-- 上传中 -->
        <div
            v-else-if="status === 1"
            class="h-100 flex items-center justify-center silver"
        >
            上传 {{ ratio }}%
        </div>

        <!-- 上传成功 -->
        <div
            v-else-if="status === 2"
            class="h-100 relative cover bg-center br-5 flex items-center justify-center"
            :style="{ backgroundImage: `url('${img}')` }"
        >
            <div class="preview pa4" @click="onPreview">预览</div>
            <div class="label">{{ labelPureText }}</div>
            <div class="close" @click="onClose"></div>
        </div>

        <!-- 上传失败 -->
        <div v-else class="h-100 relative flex items-center justify-center">
            <div class="tc red" @click="onUpload">
                上传失败<br /><span class="b">点击重新上传</span>
            </div>
            <div class="close" @click="onClose"></div>
        </div>
    </div>
</template>

<script>
// @ts-check
import { ImagePreview } from 'vant';
import { uploadImage } from './api.js';

/** 最大图片尺寸 */
const maxSize = 10 * 1024 * 1024; // 10MB

export default {
    name: 'Uploader',

    props: {
        label: {
            type: String,
            default: '默认',
        },

        img: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            /** 上传状态 0未上传 1上传中 2上传成功 3上传失败 */
            status: 0,
            /** 上传进度 */
            ratio: 0,
        };
    },

    computed: {
        /**
         * 纯文本的标签（不包含换行符）
         * @return {string}
         */
        labelPureText() {
            return this.label.replace(/\n/g, '');
        },
    },

    watch: {
        img: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.status = 2;
                }
            },
        },
    },

    created() {},

    methods: {
        /**
         * 选择图像
         */
        chooseImage() {
            const $uploadBtn = /** @type {HTMLInputElement} */ (this.$refs[
                'upload-btn'
            ]);
            if ($uploadBtn) {
                // 清空上次选中的图片地址
                $uploadBtn.value = '';
                $uploadBtn.click();
            }
        },

        /**
         * 选中文件后的回调函数
         * @param {object} e
         * @param {HTMLInputElement} e.currentTarget
         */
        onSelectFile(e) {
            const { currentTarget } = e;
            if (!currentTarget) {
                console.error('currentTarget is empty!');
                return;
            }

            const { files } = currentTarget;
            if (!files) {
                console.error('files are empty!');
                return;
            }

            const file = files[0];
            if (!file) {
                console.error('file is empty!');
                return;
            }

            this.handleSelectFile(file);
        },

        /**
         * 处理选择文件的逻辑
         * @param {File} file
         */
        handleSelectFile(file) {
            if (file.size > maxSize) {
                console.error('file too large');
                return;
            }

            this.status = 1;

            uploadImage({
                file,
                onprogress: this.onUploadProgress,
            })
                .then(res => {
                    this.handleUploadSuccess(res);
                })
                .catch(err => {
                    console.log(err);
                    this.handleUploadFail();
                });
        },

        /**
         * 图片上传进度的监听函数
         * @param {ProgressEvent} e
         */
        onUploadProgress(e) {
            const { loaded, total } = e;
            if (total <= 0) return;
            const ratio = Math.floor((loaded * 100) / total);
            this.ratio = ratio;
        },

        /**
         * 上传成功后的函数
         * @param {string | void} filename
         */
        handleUploadSuccess(filename) {
            if (filename) {
                this.$emit('success', filename);
                this.status = 2;
            }
        },

        handleUploadFail() {
            this.status = 3;
        },

        onClose() {
            this.$emit('delete');
            this.status = 0;
        },

        onUpload() {
            this.chooseImage();
        },

        onPreview() {
            ImagePreview([this.img]);
        },
    },
};
</script>

<style scoped>
.uploader {
    max-width: 108px;
    height: 90px;
    background: #fff;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.cross {
    width: 18px;
    height: 18px;
    background: url('./img/icon.blue-cross.png') no-repeat center;
    background-size: contain;
}

.close {
    width: 26px;
    height: 26px;
    background: url('./img/icon.close.png') no-repeat center;
    background-size: 12px 12px;
    position: absolute;
    top: -13px;
    right: -13px;
    z-index: 2;
    cursor: pointer;
}

.preview {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.label {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(74, 74, 74, 0.77);
    font-size: 9px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    height: 20px;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.invisible {
    display: none;
    visibility: hidden;
}
</style>
