import areaData from './city.js';

/**
 * 根据省份和城市 ID 获取地址名称
 * @param {number} provinceId 省份 Id
 * @param {number} cityId 城市 id
 * @returns {string} 地址名称
 */
export function getAddressById(provinceId, cityId) {
    if (!provinceId) return '';

    const { provinces, source } = areaData;

    let provinceName = '';
    let cityName = '';

    try {
        provinceName = provinces['CN'][provinceId] || '';
        cityName = source[provinceId][cityId]['name'] || '';
    } catch (e) {
        console.error('get address error, provinceId:', provinceId, 'cityId:', cityId);
    }

    // 防止出现重复的名称，比如“北京北京”
    if (provinceName === cityName) return provinceName;

    return provinceName + cityName;
}
