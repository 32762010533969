import Vue from 'vue';
import positionDirective from './directive.position.js';

/**
 * 判断是否空数组
 * @param {Array<any>} arr
 * @returns {boolean}
 */
export function isEmptyArray(arr) {
    return !arr || arr.length <= 0;
}

/** 注册全局自定义指令 */
export function registerDirectives() {
    Vue.use(positionDirective);
}

/**
 * 防抖函数
 * @template {Function} F
 * @param {F} fn
 * @param {number} delay 防抖延迟时间
 * @returns {F}
 */
export function debounce(fn, delay) {
    let ctx;
    let args;
    let timer = null;

    function later() {
        fn.apply(ctx, args);
        timer = null;
    }

    return function() {
        ctx = this;
        args = arguments;

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(later, delay);
    };
}

/**
 * 节流函数
 *
 * @template {Function} F
 * @param {F} fn
 * @param {number} delay 节流延时时间
 * @returns {F}
 */
export function throttle(fn, delay) {
    let ctx;
    let args;
    let previous = Date.now();

    function later() {
        fn.apply(ctx, args);
    }

    return function() {
        ctx = this;
        args = arguments;

        const now = Date.now();
        const diff = now - previous - delay;

        if (diff >= 0) {
            previous = now;
            setTimeout(later, delay);
        }
    };
}

/**
 * 从 src 拷贝指定的属性到 dst
 * @param {object} src
 * @param {object} dst
 * @param {string[]} whitelist 拷贝的键值清单
 */
export function safeCopy(src, dst, whitelist) {
    if (isEmptyArray(whitelist)) return;
    if (!src) return;
    if (!dst) return;

    whitelist.forEach(key => {
        if (src[key]) {
            dst[key] = src[key];
        }
    });
}

/**
 * 隐藏初识加载 toast
 */
export function hideWelcome() {
    document.getElementById('welcome').style.display = 'none';
}

/**
 * 增加回退刷新
 */
export function addBackReload() {
    window.addEventListener('pageshow', e => {
        if (e.persisted) {
            location.reload();
        }
    });
}

/**
 * 为图片增加阿里云特殊后缀
 * @param {string} image 原始图片地址
 * @param {string} suffix 图片后缀，比如 love.png 等
 * @return {string}
 */
export function addImageSuffix(image, suffix) {
    if (!image) return '';
    if (image.indexOf('https://thumb.qschou.com') !== 0) return image;
    if (!suffix) return image;

    return image.split('@')[0] + '@!' + suffix;
}

/**
 * 创建监听清除错误函数
 * @param {string} srckey 数据源键值
 * @param {string} [errkey] 错误数据键值
 * @return {Record<string, Function>}
 */
function createWatchErrorCleaner(srckey, errkey) {
    const result = {};

    // 如果 srckey 只是简单的 x.y.z 形式
    if (srckey.indexOf('[') === -1) {
        result[srckey] = function(val) {
            if (val) {
                this.errObj[errkey || srckey] = '';
            }
        };
    } else {
        // 处理 a.x[z] 的形式，转换为 deep 形式。否则，会报错如下：
        // Watcher only accepts dot-delimited paths
        const srckeySimple = srckey.split('[')[0];
        result[srckeySimple] = {
            handler: function(val) {
                if (val) {
                    this.errObj[errkey] = '';
                }
            },
            deep: true,
        };
    }

    return result;
}

/**
 * 创建监听清除错误函数映射表
 * @param {Array<string|Record<string, string>>|Record<string, string>} arr
 * @return {Record<string, Function>}
 */
export function createWatchErrorMapper(arr) {
    if (!arr) return {};

    if (Array.isArray(arr)) {
        let result = {};

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (typeof item === 'string') {
                result = { ...result, ...createWatchErrorCleaner(item) };
            } else {
                result = { ...result, ...createWatchErrorMapper(item) };
            }
        }

        return result;
    }

    let result = {};
    for (let key in arr) {
        const errkey = arr[key];
        result = { ...result, ...createWatchErrorCleaner(key, errkey) };
    }
    return result;
}

/**
 * 替换字符串所有空格
 * @param {string} value 源数据
 * @return {string}
 */
export function replacetStrTrimAll(value) {
    if (!value) return '';
    const str = value.toString();
    return str.replace(/\s/g, '');
}
